import { useContext, useEffect } from 'react';
import { Grid } from '@mui/material';
import { DashboardContext } from 'contexts/DashboardContext';
import { AuthContext } from 'contexts/AuthContext';
import { defaultTheme } from 'utils/theme';
import Container from 'components/Container';
import Loader from 'components/Loader';
import Pizza from './Charts/Pizza';

const App = () => {
  const { user } = useContext(AuthContext);
  const { dashData, getLoading, getDashboard } = useContext(DashboardContext);
  const { primary, secondary, grey } = defaultTheme?.palette;

  const mockData = [
    { id: 0, color: primary?.dark },
    { id: 1, color: primary?.light },
    { id: 2, color: primary?.main },
    { id: 3, color: grey[1000] },
    { id: 4, color: secondary?.dark },
    { id: 5, color: secondary?.light },
    { id: 6, color: secondary?.main },
  ];

  const renderGraph = (type) =>
    (dashData?.[type] || [])?.map((d, i) => ({ ...d, ...mockData[i] }));

  useEffect(() => {
    getDashboard();
  }, []);

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Grid container spacing={5}>
        {!user?.permissoes?.block_dashboard_requisicoes && (
          <Grid item xs={12} sm={4} display="flex">
            <Pizza
              title="Requisições de Compra"
              data={renderGraph('requisicoes')}
            />
          </Grid>
        )}
        {!user?.permissoes?.block_dashboard_pedidos && (
          <Grid item xs={12} sm={4} display="flex">
            <Pizza title="Pedidos" data={renderGraph('pedidos')} />
          </Grid>
        )}
        {!user?.permissoes?.block_dashboard_faturas && (
          <Grid item xs={12} sm={4} display="flex">
            <Pizza title="Faturas" data={renderGraph('faturas')} />
          </Grid>
        )}
        {!user?.permissoes?.block_dashboard_expedicoes && (
          <Grid item xs={12} sm={6} display="flex">
            <Pizza title="Expedições" data={renderGraph('expedicoes')} />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default App;
