import moment from 'moment';

const mock = [
  {
    field: 'status',
    renderCell: ({ value }) => renderStatus(value),
  },
  {
    field: 'dtemissao',
    valueGetter: ({ value }) => value && moment(value).format('DD/MM/YYYY'),
  },
  {
    field: 'przent',
    valueGetter: ({ value }) => value && moment(value).format('DD/MM/YYYY'),
  },
  {
    field: 'vldocto',
    valueGetter: ({ value }) =>
      value?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
  },
];

const renderStatus = (value) => {
  const color =
    value === 'CONCLUIDO'
      ? '#7FFF7F'
      : value === 'PENDENTE'
      ? '#FFFF7F'
      : value === 'CANCELADO'
      ? '#FF7F7F'
      : 'transparent';

  const style = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: color,
    borderRadius: '50px',
  };
  return (
    <div style={style}>
      <span>{value}</span>
    </div>
  );
};

export default mock;
