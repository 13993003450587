import React from 'react';
import { Grid } from '@mui/material';
import {
  dropOperacao,
  dropStatusDfe,
  dropXmlManifesto,
  dropSituacaoManifesto,
  dropTipoDoctoManifesto,
} from 'utils/drops';
import Input from 'components/Input';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';

const Filter = ({ grid, control, drops }) => {
  const checkDisplay = (name) => {
    const column = grid?.colunas?.find((c) => c?.field === name);
    if (column?.filterable) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2}>
      {checkDisplay('id') && (
        <Grid item xs={12}>
          <Input name="id" control={control} label="ID" type="number" />
        </Grid>
      )}
      {checkDisplay('situacao') && (
        <Grid item xs={12}>
          <Dropdown
            name="situacao"
            control={control}
            label="Situação"
            options={dropSituacaoManifesto}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('situacao') && (
        <Grid item xs={12}>
          <Dropdown
            name="xml"
            control={control}
            label="XML"
            options={dropXmlManifesto}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('emitente') && (
        <Grid item xs={12}>
          <Input name="rsocial" control={control} label="Razão Social" />
        </Grid>
      )}
      {checkDisplay('emitente') && (
        <Grid item xs={12}>
          <Input name="cnpj" control={control} label="CNPJ" type="number" />
        </Grid>
      )}
      {checkDisplay('emitente') && (
        <Grid item xs={12}>
          <Input name="ie" control={control} label="Inscrição Estadual" />
        </Grid>
      )}
      {checkDisplay('dhemissao') && (
        <Grid item xs={6}>
          <InputMask
            name="dhemissao.i"
            control={control}
            label="Data/Hora de Emissão Inicial"
            type="datetime"
          />
        </Grid>
      )}
      {checkDisplay('dhemissao') && (
        <Grid item xs={6}>
          <InputMask
            name="dhemissao.f"
            control={control}
            label="Data/Hora de Emissão Final"
            type="datetime"
          />
        </Grid>
      )}
      {checkDisplay('filial') && (
        <Grid item xs={12}>
          <Dropdown
            name="filial"
            control={control}
            label="Filial"
            options={drops?.Filial}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('nf') && (
        <Grid item xs={12}>
          <Input
            name="numero"
            control={control}
            label="Nº do Documento"
            type="number"
          />
        </Grid>
      )}
      {checkDisplay('nf') && (
        <Grid item xs={12}>
          <Dropdown
            name="tpdocto"
            control={control}
            label="Tipo do Documento"
            options={dropTipoDoctoManifesto}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('nf') && (
        <Grid item xs={12}>
          <Dropdown
            name="status"
            control={control}
            label="Status"
            options={dropStatusDfe}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('nf') && (
        <Grid item xs={12}>
          <Dropdown
            name="operacao"
            control={control}
            label="Operação"
            options={dropOperacao}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('nf') && (
        <Grid item xs={12}>
          <InputMask name="valor" control={control} label="Valor" />
        </Grid>
      )}
      {checkDisplay('nf') && (
        <Grid item xs={12}>
          <Input name="serie" control={control} label="Série" type="number" />
        </Grid>
      )}
    </Grid>
  );
};

export default Filter;
