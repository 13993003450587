const styles = {
  card: {
    marginTop: '1em',
  },
  button: {
    margin: '1em 0',
  },
  semiCard: {
    width: '100%',
  },
};

export default styles;
