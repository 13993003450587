import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { summarizer } from 'utils/functions';
import { ExpedicoesContext } from 'contexts/ExpedicoesContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import Container from 'components/Container';
import Header from 'components/Header';
import Button from 'components/Button';
import Card from 'components/Card';
import Loader from 'components/Loader';

const Estorno = () => {
  const navigate = useNavigate();
  const defaultValues = { itens: [], movimentos: [] };
  const { documento_id } = useParams();
  const { handleSubmit, watch, setValue } = useForm({ defaultValues });
  const { deleteLoading, deleteExpedicao } = useContext(ExpedicoesContext);
  const { getDocumento, getLoading, documento } = useContext(DocumentosContext);

  useEffect(() => {
    if (Boolean(documento_id)) {
      getDocumento(documento_id);
    }
  }, []);

  useEffect(() => {
    if (Boolean(documento_id) && Boolean(documento)) {
      let movimentos = [];
      setValue('itens', []);
      documento?.DocumentoItems?.map((di) => {
        const gerados = di?.Destinos?.filter(
          (d) => d?.especie_natureza === 'FATURA'
        );
        const quantidade_max =
          (Math.abs(summarizer(di?.Ceexpedicaos, 'quantidade')) || 0) -
          (summarizer(gerados, 'quantidade') || 0);
        if (quantidade_max > 0) {
          setValue('itens', [
            ...watch('itens'),
            {
              id: di?.id,
              quantidade_max,
              descricao: di?.descricao,
              referencia: di?.referencia,
            },
          ]);
        }
        di?.Ceexpedicaos?.map((mov) => {
          const isSame = movimentos?.some(
            (s) =>
              s?.documento_item_id === mov?.documento_item_id &&
              s?.celocal_id === mov?.celocal_id
          );
          if (!isSame) {
            movimentos?.push({
              id: movimentos?.length + 1,
              documento_item_id: di?.id,
              descricao: di?.descricao,
              quantidade_max,
              quantidade: 0,
              celocal_id: mov?.celocal_id,
              celocal: mov?.celocal,
              referencia: di?.referencia,
            });
          }
        });
      });
      setValue('movimentos', movimentos);
    }
  }, [documento]);

  const onCellEditCommit = (e) => {
    const movimentos = watch('movimentos')?.map((c) => {
      if (c?.id === e?.id) {
        const quantidade_max =
          summarizer(
            watch('itens')?.filter((i) => i?.id === c?.documento_item_id),
            'quantidade_max'
          ) || 0;

        const quantidade_estornada =
          summarizer(
            watch('movimentos')?.filter((i) => i?.id !== c?.id),
            'quantidade'
          ) || 0;
        if (
          e?.field === 'quantidade' &&
          quantidade_max - (quantidade_estornada + (e?.value || 0)) >= 0
        ) {
          return { ...c, [e?.field]: e?.value };
        }
        return c;
      } else {
        return c;
      }
    });
    setValue('movimentos', movimentos);
  };

  const onSubmit = (values) => {
    const data = {
      documento_id,
      movimentos: values?.movimentos?.map((m) => ({
        documento_item_id: m?.documento_item_id,
        quantidade: m?.quantidade,
        celocal_id: m?.celocal_id,
      })),
    };
    deleteExpedicao({ data, cb: () => navigate(-1) });
  };

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo="Estorno de Expedição" />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body2" color="secondary">
              Para cancelar a expedição, é necessário estornar os seguintes
              itens não faturados:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {watch('itens')?.map((m, i) => (
              <Typography key={i?.toString()} variant="body2" color="secondary">
                {m?.quantidade_max}x {m?.descricao}
              </Typography>
            ))}
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              rows={watch('movimentos')}
              columns={[
                {
                  field: 'referencia',
                  headerName: 'Referência',
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'descricao',
                  headerName: 'Descrição',
                  flex: 4,
                  sortable: false,
                },
                {
                  field: 'celocal',
                  headerName: 'Local de Estoque',
                  flex: 2,
                  sortable: false,
                },
                {
                  field: 'quantidade',
                  headerName: 'Quantidade',
                  type: 'number',
                  flex: 1,
                  sortable: false,
                  editable: true,
                },
              ]}
              hideFooter
              autoHeight
              density="compact"
              disableSelectionOnClick
              disableColumnMenu
              showCellRightBorder
              showColumnRightBorder
              localeText={{ noRowsLabel: 'Nenhum Registro.' }}
              onCellEditCommit={onCellEditCommit}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              color="primary"
              variant="contained"
              loading={deleteLoading}
              onClick={handleSubmit(onSubmit)}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Estorno;
