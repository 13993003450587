import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  situacao: [],
  xml: ['IMPORTAR', 'INDISPONIVEL'],
  dhemissao: {
    i: null,
    f: null,
  },
  rsocial: '',
  cnpj: '',
  ie: '',
  filial: [],
  numero: '',
  tpdocto: [],
  status: [],
  operacao: [],
  valor: '',
  serie: '',
};

export const ManifestacoesContext = createContext();

export const ManifestacoesProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [manifestacoes, setManifestacoes] = useState({
    data: [],
    colunas: [],
    order: { field: 'dhemissao', sort: 'desc' },
    filter,
    page: 0,
    size: 10,
  });

  const getManifestacoes = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: manifestacoes?.filter,
        page: manifestacoes?.page,
        size: manifestacoes?.size,
        order: manifestacoes?.order,
        ...payload,
      });
      setManifestacoes((prev) => ({ ...prev, ...data, ...payload }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postConsulta = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post(`/Suprimentos/Manifestacao/Consulta`, data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postImportar = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post(`/Suprimentos/Manifestacao/Importar/`, data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postCiencia = async ({ id, cb }) => {
    try {
      setPostLoading(true);
      await api.post(`/Suprimentos/Manifestacao/Ciencia/${id}`);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postConfirmacao = async ({ id, cb }) => {
    try {
      setPostLoading(true);
      await api.post(`/Suprimentos/Manifestacao/Confirmacao/${id}`);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postDesconhecimento = async ({ id, cb }) => {
    try {
      setPostLoading(true);
      await api.post(`/Suprimentos/Manifestacao/Desconhecimento/${id}`);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postOperacaoNaoRealizada = async ({ id, justificativa, cb }) => {
    try {
      setPostLoading(true);
      await api.post(`/Suprimentos/Manifestacao/OperacaoNaoRealizada`, {
        id,
        justificativa,
      });
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  return (
    <ManifestacoesContext.Provider
      value={{
        manifestacoes,
        getLoading,
        postLoading,
        getManifestacoes,
        postConsulta,
        postImportar,
        postCiencia,
        postConfirmacao,
        postDesconhecimento,
        postOperacaoNaoRealizada,
      }}
    >
      {children}
    </ManifestacoesContext.Provider>
  );
};
