import { useContext } from 'react';
import { DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AuthContext } from 'contexts/AuthContext';
import { DropsContext } from 'contexts/DropsContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import { RequisicoesContext } from 'contexts/RequisicoesContext';
import { useModal } from 'components/Modals';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Dropdown from 'components/Dropdown';
import VirtualDrop from 'components/VirtualDrop';
import moment from 'moment';

const CotacaoModal = ({ itens = [], reload = null }) => {
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { requisicoes } = useContext(RequisicoesContext);
  const { getURLRelatorio } = useContext(RelatoriosContext);
  const { postDocumento, postLoading } = useContext(DocumentosContext);
  const defaultValues = {
    cadastro_id: null,
    filial_id: user?.filial_id,
    envio: false,
  };
  const { control, handleSubmit } = useForm({ defaultValues });
  const { closeModal } = useModal();

  const sortArray = (arr, field = 'id', order = 'asc') => {
    return arr.sort((a, b) => {
      if (a[field] < b[field]) {
        return order === 'asc' ? -1 : 1;
      }
      if (a[field] > b[field]) {
        return order === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const onConfirm = (values) => {
    const Itens = sortArray(
      itens,
      requisicoes?.order?.field,
      requisicoes?.order?.sort
    )?.map((i, index) => ({
      produto_id: i?.produto_id,
      descricao: i?.descricao,
      quantidade: i?.quantidade,
      unidade_medida_id: i?.unidade_medida_id,
      referencia: i?.referencia,
      infadc: i?.infadc,
      sequencia: index + 1,
      Origens: [{ documento_item_org_id: i?.id }],
    }));
    const data = {
      Documento: {
        especie_id: 3,
        natureza_operacao_id: 1,
        users_id: user?.id,
        filial_id: values?.filial_id,
        cadastro_id: values?.cadastro_id,
        dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
      },
      Entrada: {
        dtentrada: moment().format('YYYY-MM-DD HH:mm:ss'),
      },
      CotacaoCompra: {},
      Itens,
    };
    postDocumento({
      data,
      cb: (res) => {
        if (reload) {
          reload();
        }
        if (Boolean(values?.envio)) {
          getURLRelatorio({
            data: { codigo: 'DOC0000002', documento: res?.id },
            cb: closeModal,
          });
        } else {
          closeModal();
        }
      },
    });
  };

  return (
    <>
      <DialogTitle>Cotação de Compra</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <Dropdown
              name="filial_id"
              control={control}
              label="Filial"
              options={drops?.Filial}
            />
          </Grid>
          <Grid item xs={12}>
            <VirtualDrop
              name="cadastro_id"
              control={control}
              label="Fornecedor"
              options={drops?.Entidade}
            />
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              name="envio"
              control={control}
              label="Enviar para o fornecedor"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          loading={postLoading}
          onClick={handleSubmit(onConfirm)}
        >
          CONFIRMAR
        </Button>
        <Button variant="contained" color="secondary" onClick={closeModal}>
          VOLTAR
        </Button>
      </DialogActions>
    </>
  );
};

export default CotacaoModal;
