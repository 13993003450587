import React, { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { DocumentosContext } from 'contexts/DocumentosContext';
import Button from 'components/Button';
import Input from 'components/Input';

const JustificativaModal = ({ documento_id, callback }) => {
  const defaultValues = { motivo: '' };
  const { control, handleSubmit } = useForm({ defaultValues });
  const { postDocumento, postLoading } = useContext(DocumentosContext);

  const onSubmit = ({ motivo }) => {
    const data = {
      Documento: {
        id: documento_id,
        conferido: 'NAO',
      },
      Registros: [{ documento_id, status_id: 2, motivo }],
    };
    postDocumento({
      data,
      cb: () => {
        if (callback) {
          callback();
        }
      },
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" component="div" align="center">
          Informações Gerais
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Input
          name="motivo"
          control={control}
          label="Justificativa de cancelamento"
          multiline
          rows={10}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          loading={postLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};

export default JustificativaModal;
