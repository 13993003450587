import React from 'react';
import { Outlet, Route, Routes } from 'react-router';
import App from 'views/App';
import Resumo from 'views/Resumo';
import Configurações from 'views/Configurações';
import User from 'views/User';
import Estoque from 'views/Estoque';
import EstoqueInventário from 'views/Estoque/Inventário';
import EstoqueTransferência from 'views/Estoque/Transferência';
import EstoqueMovimentação from 'views/Estoque/Movimentação';
import Retornáveis from 'views/Retornáveis';
import RetornáveisPerda from 'views/Retornáveis/Perda';
import RetornáveisVenda from 'views/Retornáveis/Venda';
import RetornáveisEstoque from 'views/Retornáveis/Estoque';
import RetornáveisDetalhes from 'views/Retornáveis/Detalhes';
import Notas from 'views/Notas';
import NotasGerar from 'views/Notas/Gerar';
import NotasEtiquetas from 'views/Notas/Etiquetas';
import Pedidos from 'views/Pedidos';
import PedidosGerar from 'views/Pedidos/Gerar';
import Requisições from 'views/Requisições';
import RequisiçõesGerar from 'views/Requisições/Gerar';
import RequisiçõesNecessidade from 'views/Requisições/Necessidade';
import Manifestações from 'views/Manifestações';
import Expedições from 'views/Expedições';
import ExpediçõesGerar from 'views/Expedições/Gerar';
import ExpediçõesEstorno from 'views/Expedições/Estorno';
import Documento from 'views/Documento';
import DocumentoItem from 'views/Documento/Item';
import DocumentoVinculo from 'views/Documento/Vinculo';
import DocumentoDevolução from 'views/Documento/Devolução';
import DocumentoAprovações from 'views/Documento/Aprovações';
import DocumentoFormaçãoDePreço from 'views/Documento/FormaçãoDePreço';
//RELATÓRIOS
import Relatórios from 'views/Relatórios';
import RelatóriosFiltros from 'views/Relatórios/Filtros';
//OUTLETS
import OutletDocumento from './Outlets/Documento';

const RoutesTree = () => (
  <Routes>
    <Route path="" element={<App />} />
    <Route path="Resumo" element={<Resumo />} />
    <Route path="Configuracoes" element={<Configurações />} />
    <Route path="User" element={<User />} />
    <Route path="Estoque" element={<Outlet />}>
      <Route path="" element={<Estoque />} />
      <Route path="Inventario" element={<EstoqueInventário />} />
      <Route path="Transferencia" element={<EstoqueTransferência />} />
      <Route path="Movimentacao" element={<EstoqueMovimentação />} />
    </Route>
    <Route path="Relatorios" element={<Outlet />}>
      <Route path="" element={<Relatórios />} />
      <Route path="Filtros" element={<RelatóriosFiltros />} />
    </Route>
    <Route path="Retornaveis" element={<Outlet />}>
      <Route path="" element={<Retornáveis />} />
      <Route path="Perda" element={<RetornáveisPerda />} />
      <Route path="Venda" element={<RetornáveisVenda />} />
      <Route path="Estoque" element={<RetornáveisEstoque />} />
      <Route path="Detalhes" element={<RetornáveisDetalhes />} />
    </Route>
    <Route path="Notas" element={<Outlet />}>
      <Route path="" element={<Notas />} />
      <Route path="Etiquetas" element={<NotasEtiquetas />} />
      <Route path="Gerar" element={<Outlet />}>
        <Route path="NF/:documento_id?" element={<NotasGerar />} />
        <Route path="NFS/:documento_id?" element={<NotasGerar />} />
        <Route path="Fatura/:documento_id?" element={<NotasGerar />} />
      </Route>
    </Route>
    <Route path="Pedidos" element={<Outlet />}>
      <Route path="" element={<Pedidos />} />
      <Route
        path="Gerar/:documento_id?/:selected_itens?"
        element={<PedidosGerar />}
      />
    </Route>
    <Route path="Requisicoes" element={<Outlet />}>
      <Route path="" element={<Requisições />} />
      <Route
        path="Gerar/:documento_id?/:selected_itens?"
        element={<RequisiçõesGerar />}
      />
      <Route path="Necessidade" element={<RequisiçõesNecessidade />} />
    </Route>
    <Route path="Manifestacoes" element={<Outlet />}>
      <Route path="" element={<Manifestações />} />
    </Route>
    <Route path="Expedicoes" element={<Outlet />}>
      <Route path="" element={<Expedições />} />
      <Route path="Gerar" element={<Outlet />}>
        <Route path=":documento_id" element={<ExpediçõesGerar />} />
      </Route>
      <Route path="Estorno" element={<Outlet />}>
        <Route path=":documento_id" element={<ExpediçõesEstorno />} />
      </Route>
    </Route>
    <Route path="Documento" element={<OutletDocumento />}>
      <Route path=":id" element={<Documento />} />
      <Route path="Item" element={<DocumentoItem />} />
      <Route path="Vinculo/:hash" element={<DocumentoVinculo />} />
      <Route path="Aprovacoes" element={<DocumentoAprovações />} />
      <Route path="Devolucao" element={<DocumentoDevolução />} />
      <Route path="FormacaoDePreco" element={<DocumentoFormaçãoDePreço />} />
    </Route>
  </Routes>
);

export default RoutesTree;
