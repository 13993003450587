import React, { useContext } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { ManifestacoesContext } from 'contexts/ManifestacoesContext';
import { DropsContext } from 'contexts/DropsContext';
import { AuthContext } from 'contexts/AuthContext';
import { useModal } from 'components/Modals';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';

const TipoConsultaModal = ({ onSubmit }) => {
  const { closeModal } = useModal();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { postLoading } = useContext(ManifestacoesContext);
  const defaultValues = {
    tpserv: null,
    produto: false,
    transportador: false,
    filial_id: user?.filial_id,
  };
  const { control, watch, setValue, handleSubmit } = useForm({ defaultValues });

  const options = [
    { value: 'nfe', label: 'NFe' },
    { value: 'cte', label: 'CTe' },
    { value: 'mdfe', label: 'MDFe' },
  ];

  return (
    <>
      <DialogTitle>Buscar por...</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl>
              <RadioGroup onChange={(_, v) => setValue('tpserv', v)}>
                {options?.map((c) => {
                  return (
                    <FormControlLabel
                      key={c?.value}
                      value={c?.value}
                      label={c?.label}
                      control={<Radio />}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              Configurações para importação do XML
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} display="flex" alignItems="center">
            <Switch
              checked={watch('produto')}
              onChange={(_, v) => setValue('produto', v)}
            />
            <Typography>Cadastramento automático de produtos</Typography>
          </Grid>
          <Grid item xs={12} md={6} display="flex" alignItems="center">
            <Switch
              checked={watch('transportador')}
              onChange={(_, v) => setValue('transportador', v)}
            />
            <Typography>Cadastramento automático do transportador</Typography>
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="filial_id"
              control={control}
              label="Filial"
              options={drops?.Filial}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button loading={postLoading} onClick={handleSubmit(onSubmit)}>
          Confirmar
        </Button>
        <Button onClick={closeModal} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default TipoConsultaModal;
