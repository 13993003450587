import React from 'react';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import Card from 'components/Card';
import TableContainer from 'components/TableContainer';
import styles from '../styles';
import moment from 'moment';
import { Delete } from '@mui/icons-material';

const Vínculos = ({ documentoItem = {}, onDeleteVinculo, disableActions }) => (
  <Card title="Vínculos" style={styles?.card}>
    <Grid container spacing={2}>
      {!!documentoItem?.Vinculados?.length && (
        <Grid item xs={12}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Documento</TableCell>
                  <TableCell>Data de Emissão</TableCell>
                  <TableCell align="right">Quantidade</TableCell>
                  <TableCell align="center">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {documentoItem?.Vinculados?.map((item) => (
                  <TableRow key={item?.id?.toString()}>
                    <TableCell>{item?.documento}</TableCell>
                    <TableCell>
                      {Boolean(item?.dtemissao) &&
                        moment(item?.dtemissao).isValid() &&
                        moment(item?.dtemissao).format('DD/MM/YYYY [às] HH:mm')}
                    </TableCell>
                    <TableCell align="right">{item?.quantidade}</TableCell>
                    <TableCell align="center">
                      {!disableActions && (
                        <IconButton
                          size="small"
                          onClick={() => onDeleteVinculo(item)}
                        >
                          <Delete />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </Grid>
  </Card>
);

export default Vínculos;
