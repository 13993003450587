import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Menu as MenuIcon,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Notifications,
} from '@mui/icons-material';
import { AuthContext } from 'contexts/AuthContext';
import { DropsContext } from 'contexts/DropsContext';
import { NotificaçõesContext } from 'contexts/NotificaçõesContext';
import { useDrawer } from 'components/Modals';
import Button from 'components/Button';
import NotificacaoModal from 'components/Modals/NotificacaoModal';
import styles from './styles';

const Navbar = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const { openDrawer: openDrw } = useDrawer();
  const { drops } = useContext(DropsContext);
  const { user, logout } = useContext(AuthContext);
  const { notifications } = useContext(NotificaçõesContext);
  const navigate = useNavigate();

  const handleOpenDrawer = () => setOpenDrawer(true);

  const handleOpenUserMenu = (event) => setAnchorElUser(event?.currentTarget);

  const handleCloseNavMenu = () => setOpenDrawer(false);

  const handleCloseUserMenu = () => setAnchorElUser(null);

  const handleNotification = () =>
    openDrw(<NotificacaoModal />, 'Notificações');

  const handleSetting = (action) => {
    if (action) action();
    handleCloseUserMenu();
  };

  const handleMobile = (action) => {
    if (action) action();
    handleCloseNavMenu();
  };

  const badgeContent = notifications?.filter(
    (f) => f?.finalizado === 'NAO'
  )?.length;

  const options = [
    {
      name: 'Início',
      visible: !Boolean(drops?.Logo?.logo),
      action: () => navigate('/app'),
    },
    {
      name: 'Estoque',
      visible: !user?.permissoes?.block_estoque,
      action: () => navigate('/app/Estoque'),
    },
    {
      name: 'Compras',
      visible: !user?.permissoes?.block_compras,
      children: [
        {
          name: 'Notas',
          visible: !user?.permissoes?.block_compras_notas,
          action: () => navigate('/app/Notas'),
        },
        {
          name: 'Pedidos',
          visible: !user?.permissoes?.block_compras_pedidos,
          action: () => navigate('/app/Pedidos'),
        },

        {
          name: 'Requisições',
          visible: !user?.permissoes?.block_compras_requisicoes,
          action: () => navigate('/app/Requisicoes'),
        },
      ],
    },
    {
      name: 'Manifestações',
      visible: !user?.permissoes?.block_manifestacoes,
      action: () => navigate('/app/Manifestacoes'),
    },
    {
      name: 'Expedições',
      visible: !user?.permissoes?.block_expedicoes,
      action: () => navigate('/app/Expedicoes'),
    },
    {
      name: 'Retornáveis',
      visible: !user?.permissoes?.block_retornaveis,
      action: () => navigate('/app/Retornaveis'),
    },
    {
      name: 'Relatórios',
      visible: !user?.permissoes?.block_relatorios,
      action: () => navigate('/app/Relatorios'),
    },
  ];

  const settings = [
    { name: 'Perfil', visible: true, action: () => navigate('/app/User') },
    { name: 'Sair', visible: true, action: () => logout() },
  ];

  return (
    <AppBar position="fixed">
      <Toolbar disableGutters sx={styles?.toolbar}>
        <Box sx={styles?.menuMobile}>
          <IconButton onClick={handleOpenDrawer} color="inherit">
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor="left"
            open={openDrawer}
            onClose={handleCloseNavMenu}
            PaperProps={styles?.menuMobileDrawer}
          >
            <List>
              {options?.map(({ name, visible, action, children }, i) => {
                if (!visible) return null;
                if (Boolean(children?.length)) {
                  return (
                    <Box key={i?.toString()}>
                      <ListItem disablePadding>
                        <ListItemButton
                          onClick={() =>
                            !!openCollapse && openCollapse === name
                              ? setOpenCollapse(false)
                              : setOpenCollapse(name)
                          }
                        >
                          <ListItemText primary={name} />
                          <ListItemIcon sx={styles?.iconList}>
                            {openCollapse === name ? (
                              <KeyboardArrowUp
                                fontSize="small"
                                sx={styles?.icon}
                              />
                            ) : (
                              <KeyboardArrowDown
                                fontSize="small"
                                sx={styles?.icon}
                              />
                            )}
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                      <Collapse
                        in={openCollapse === name}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List>
                          {children?.map(
                            ({ name, action, visible }, i) =>
                              visible && (
                                <ListItem key={i?.toString()} disablePadding>
                                  <ListItemButton
                                    onClick={() => handleMobile(action)}
                                  >
                                    <ListItemText
                                      primary={name}
                                      sx={styles?.textList}
                                    />
                                  </ListItemButton>
                                </ListItem>
                              )
                          )}
                        </List>
                      </Collapse>
                    </Box>
                  );
                }
                return (
                  <ListItem key={i?.toString()} disablePadding>
                    <ListItemButton onClick={() => handleMobile(action)}>
                      <ListItemText primary={name} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
            <Divider sx={styles?.icon} />
            <List>
              {settings?.map((setting) => (
                <ListItem key={setting?.name} disablePadding>
                  <ListItemButton onClick={setting?.action}>
                    <ListItemText primary={setting?.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Drawer>
        </Box>
        {Boolean(drops?.Logo?.logo) && (
          <Box sx={styles?.menuMobile}>
            <Box sx={styles?.logoContainer} onClick={() => navigate('/app')}>
              <img src={drops?.Logo?.logo} style={styles?.logo} />
            </Box>
          </Box>
        )}
        <Box sx={styles?.menuWide}>
          {Boolean(drops?.Logo?.logo) && (
            <Box sx={styles?.logoContainer} onClick={() => navigate('/app')}>
              <img src={drops?.Logo?.logo} style={styles?.logo} />
            </Box>
          )}
          {options?.map(({ name, visible, action, children }, i) => {
            if (!visible) return null;
            if (Boolean(children?.length)) {
              const [anchorEl, setAnchorEl] = useState(null);
              return (
                <Box key={i?.toString()} sx={styles?.menuWideButtonContainer}>
                  <Button
                    endIcon={<KeyboardArrowDown fontSize="medium" />}
                    sx={styles?.menuWideButton}
                    onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
                  >
                    {name}
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={!!anchorEl}
                    onClose={() => setAnchorEl(null)}
                  >
                    {children?.map(
                      ({ name, action, visible }, i) =>
                        visible && (
                          <MenuItem
                            key={i?.toString()}
                            disabled={!anchorEl}
                            onClick={() => {
                              setAnchorEl(null);
                              if (action) action();
                            }}
                          >
                            {name}
                          </MenuItem>
                        )
                    )}
                  </Menu>
                </Box>
              );
            }
            return (
              <Box key={i?.toString()} sx={styles?.menuWideButtonContainer}>
                <Button onClick={action} sx={styles?.menuWideButton}>
                  {name}
                </Button>
              </Box>
            );
          })}
        </Box>
        <Box sx={styles?.notificationContainer}>
          <IconButton onClick={handleNotification}>
            <Badge badgeContent={badgeContent} color="secondary" max={99}>
              <Notifications sx={styles?.icon} />
            </Badge>
          </IconButton>
        </Box>
        <Box sx={styles?.avatarWide}>
          <Tooltip
            title={
              <Box sx={styles?.tooltip}>{user?.name + '\n' + user?.filial}</Box>
            }
            placement="left"
          >
            <IconButton onClick={handleOpenUserMenu}>
              <Avatar alt={user?.name} src="/static/images/avatar/2.jpg" />
            </IconButton>
          </Tooltip>
          <Menu
            sx={styles?.menuSettings}
            anchorEl={anchorElUser}
            anchorOrigin={styles?.menuSettingsAnchor}
            keepMounted
            transformOrigin={styles?.menuSettingsOrigin}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings?.map(
              ({ action, name, visible }, i) =>
                visible && (
                  <MenuItem
                    key={i?.toString()}
                    disabled={!anchorElUser}
                    onClick={() => handleSetting(action)}
                  >
                    <Typography textAlign="center">{name}</Typography>
                  </MenuItem>
                )
            )}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
