import { Box, Typography } from '@mui/material';
import moment from 'moment';

const mock = [
  {
    field: 'status',
    renderCell: ({ value }) => renderStatus(value),
  },
  {
    field: 'dtemissao',
    valueGetter: ({ value }) => value && moment(value).format('DD/MM/YYYY'),
  },
  {
    field: 'preco',
    valueGetter: ({ value }) =>
      value?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
  },
  {
    field: 'historico',
    renderCell: ({ row }) => {
      if (row.status === 'CONCLUIDO') {
        const pedido = row?.historico?.split('\n')?.find((f) => Boolean(f));
        return (
          <Box my={1}>
            {Boolean(pedido) && (
              <Typography fontWeight="bold" variant="body2">
                Pedido
              </Typography>
            )}
            {Boolean(pedido) && (
              <Typography variant="caption">• {pedido}</Typography>
            )}
          </Box>
        );
      }
      if (row?.status === 'PENDENTE') {
        const cotacoes = row?.historico
          ?.split('\n')
          ?.slice(0, 3)
          ?.filter((f) => Boolean(f));

        const compras = row?.historico
          ?.split('\n')
          ?.slice(3, 6)
          ?.filter((f) => Boolean(f));
        return (
          <Box my={1}>
            {Boolean(compras?.length) && (
              <Typography fontWeight="bold" variant="body2">
                Últimas Compras
              </Typography>
            )}
            {compras?.map((cp, i) => (
              <Box key={`${i}`}>
                <Typography variant="caption">• {cp}</Typography>
              </Box>
            ))}
            {Boolean(cotacoes?.length) && (
              <Typography
                fontWeight="bold"
                variant="body2"
                mt={Boolean(compras?.length) ? 2 : 0}
              >
                Cotações Enviadas
              </Typography>
            )}
            {cotacoes?.map((ct, i) => (
              <Box key={`${i}`}>
                <Typography variant="caption">• {ct}</Typography>
              </Box>
            ))}
          </Box>
        );
      }
      return null;
    },
  },
];

const renderStatus = (value) => {
  const color =
    value === 'CONCLUIDO'
      ? '#7FFF7F'
      : value === 'PENDENTE'
      ? '#FFFF7F'
      : value === 'CANCELADO'
      ? '#FF7F7F'
      : 'transparent';

  const style = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: color,
    borderRadius: '50px',
  };
  return (
    <div style={style}>
      <span>{value}</span>
    </div>
  );
};

export default mock;
