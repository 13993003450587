import { AuthProvider } from 'contexts/AuthContext';
import { NotificaçõesProvider } from 'contexts/NotificaçõesContext';
import { GridProvider } from 'contexts/GridContext';
import { DropsProvider } from 'contexts/DropsContext';
import { DocumentosProvider } from 'contexts/DocumentosContext';
import { ProdutosProvider } from 'contexts/ProdutosContext';
import { RelatoriosProvider } from 'contexts/RelatoriosContext';
import { RetornaveisProvider } from 'contexts/RetornaveisContext';
import { NotasProvider } from 'contexts/NotasContext';
import { PedidosProvider } from 'contexts/PedidosContext';
import { RequisicoesProvider } from 'contexts/RequisicoesContext';
import { ManifestacoesProvider } from 'contexts/ManifestacoesContext';
import { ExpedicoesProvider } from 'contexts/ExpedicoesContext';
import { EstoqueProvider } from 'contexts/EstoqueContext';
import { DashboardProvider } from 'contexts/DashboardContext';
import {
  DialogProvider,
  ModalProvider,
  DrawerProvider,
} from 'components/Modals';

const Providers = ({ children }) => (
  <AuthProvider>
    <NotificaçõesProvider>
      <GridProvider>
        <DropsProvider>
          <RelatoriosProvider>
            <DashboardProvider>
              <DocumentosProvider>
                <ProdutosProvider>
                  <RetornaveisProvider>
                    <NotasProvider>
                      <PedidosProvider>
                        <RequisicoesProvider>
                          <ManifestacoesProvider>
                            <ExpedicoesProvider>
                              <EstoqueProvider>
                                <ModalProvider>
                                  <DialogProvider>
                                    <DrawerProvider>{children}</DrawerProvider>
                                  </DialogProvider>
                                </ModalProvider>
                              </EstoqueProvider>
                            </ExpedicoesProvider>
                          </ManifestacoesProvider>
                        </RequisicoesProvider>
                      </PedidosProvider>
                    </NotasProvider>
                  </RetornaveisProvider>
                </ProdutosProvider>
              </DocumentosProvider>
            </DashboardProvider>
          </RelatoriosProvider>
        </DropsProvider>
      </GridProvider>
    </NotificaçõesProvider>
  </AuthProvider>
);

export default Providers;
