const styles = {
  card: {
    marginTop: '1em',
  },
  button: {
    alignSelf: 'center',
    my: '1em',
  },
};

export default styles;
