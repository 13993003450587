import moment from 'moment';
import CryptoJS from 'crypto-js';
import api from 'services/api';

export const findOnArray = (value, arr = [], field) => {
  const rec = arr.find((i) => i.value === value);
  if (field) {
    return rec?.[field] || null;
  }
  return rec || null;
};

export const summarizer = (arr = [], field) =>
  arr?.length &&
  Number(
    arr
      ?.map((i) => i[field])
      ?.reduce((prev, next) => prev + next)
      ?.toFixed(2)
  );

export const checkInfosProduto = async ({ produto_id, ...rest }) => {
  const { data } = await api.get(`/Cadastros/Produto/${produto_id}`, {
    params: { ...rest, Midias: true, Descricao: true },
  });
  const imagem = data?.ProdutoImagems?.find((f) => f?.Midium?.tipo === 'IMAGEM')
    ?.Midium?.url;
  const descricao = data?.ProdutoDescricaos?.find(
    (f) => f?.tipo === 'COMPLETA' || f?.tipo === 'REDUZIDA'
  )?.descricao;
  return { ...data, imagem, descricao };
};

export const openXML = (xml) => {
  const blob = new Blob([xml], { type: 'text/xml' });
  const url = URL.createObjectURL(blob);
  window.open(url);
  URL.revokeObjectURL(url);
};

export const getDatePagto = (values) => {
  let start_date = moment(values?.start_date).format('YYYY-MM-DD');
  let count_fora = 1;
  if (values?.entrada === 'SIM') {
    count_fora = 2;
    start_date = moment(start_date).add(1, 'day').format('YYYY-MM-DD');
    if (values?.parcela === 1) {
      return start_date;
    }
  }
  if (values?.fora === 'SEMANA') {
    start_date = moment(start_date).add(1, 'week').format('YYYY-MM-DD');
    if (values?.parcela === count_fora) {
      return start_date;
    }
  }
  if (values?.fora === 'QUINZENA') {
    start_date = moment(start_date).add(15, 'days').format('YYYY-MM-DD');
    if (values?.parcela === count_fora) {
      return start_date;
    }
  }
  if (values?.fora === 'MES') {
    start_date = moment(start_date).add(1, 'month').format('YYYY-MM-DD');
    if (values?.parcela === count_fora) {
      return start_date;
    }
  }

  if (Boolean(values?.intervalop >= 0)) {
    start_date = moment(start_date)
      .add(values?.intervalop, 'days')
      .format('YYYY-MM-DD');
    if (values?.parcela !== 1) {
      start_date = moment(start_date)
        .add(values?.intervalo * (values?.parcela - 1), 'days')
        .format('YYYY-MM-DD');
    }
  } else {
    start_date = moment(start_date)
      .add(values?.intervalo * values?.parcela, 'days')
      .format('YYYY-MM-DD');
  }

  if (values?.fixar && values?.intervalo % 30 === 0) {
    const diaFixo = moment(values?.start_date).format('DD');
    start_date = moment(start_date).set('D', diaFixo).format('YYYY-MM-DD');
  }

  if (Boolean(values?.dia)) {
    while (Number(moment(start_date).format('DD')) !== values?.dia) {
      start_date = moment(start_date).add(1, 'day').format('YYYY-MM-DD');
    }
  }
  return start_date;
};

export const encryptURL = (word, key = 'E-Solution') => {
  let encJson = CryptoJS.AES.encrypt(JSON.stringify(word), key).toString();
  let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
  return encData;
};

export const decryptURL = (word, key = 'E-Solution') => {
  let decData = CryptoJS.enc.Base64.parse(word).toString(CryptoJS.enc.Utf8);
  let bytes = CryptoJS.AES.decrypt(decData, key).toString(CryptoJS.enc.Utf8);
  try {
    const a = JSON.parse(bytes);
    return a;
  } catch (error) {
    return null;
  }
};

export const filterSearch = (data = [], value = '') =>
  data?.filter((f) => {
    return Object.keys(f).some(
      (key) =>
        (f[key] || '')
          ?.toString()
          ?.toLowerCase()
          ?.indexOf(value?.toLowerCase()) !== -1
    );
  });

export const round = (valor, casas = 2) => {
  if (isNaN(Number(valor))) {
    return valor;
  }

  const potencia = 10 ** casas;
  const valor_elevado = Number(valor) * potencia;
  const string_direita = valor_elevado?.toString()?.split('.')?.[1] || '';
  let numeros_direita = string_direita?.length + 1;

  if (numeros_direita >= 12 && string_direita?.includes('999')) {
    numeros_direita--;
  }

  const soma_direita = 10 ** numeros_direita;
  const somador = 1 / soma_direita;
  return Math.round(Math.round(valor_elevado + somador), casas) / potencia;
};
