import { createContext, useState } from 'react';
import api from 'services/api';
import moment from 'moment';

const filter = {
  like: '',
  id: '',
  status: [],
  situacao: [],
  natureza_operacao: [],
  dtemissao: {
    i: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    f: null,
  },
  dtentrada: {
    i: null,
    f: null,
  },
  documento: '',
  entidade: [],
  vldocto: '',
  filial: [],
  whatsapp: '',
};

export const NotasContext = createContext();

export const NotasProvider = ({ children }) => {
  const [notas, setNotas] = useState({
    data: [],
    colunas: [],
    order: { field: 'dtemissao', sort: 'desc' },
    filter,
    page: 0,
    size: 10,
  });
  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [previsoes, setPrevisoes] = useState([]);

  const getNotas = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: notas?.filter,
        page: notas?.page,
        size: notas?.size,
        order: notas?.order,
        ...payload,
      });
      setNotas((prev) => ({ ...prev, ...data, ...payload }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const exportXML = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post(`/Comercial/Notas/Exportar`, data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const exportXMLAll = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post(`/Comercial/Notas/Exportar/All`, data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const importXML = async ({ formData, cb }) => {
    try {
      setPostLoading(true);
      await api.post(`/Comercial/Notas/Importar`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const getPrevisoes = async (params) => {
    try {
      setPrevisoes(null);
      setGetLoading(true);
      const { data } = await api.get('/Suprimentos/Compra/Previsoes', {
        params,
      });
      setPrevisoes(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <NotasContext.Provider
      value={{
        notas,
        getNotas,
        getLoading,
        postLoading,
        exportXML,
        exportXMLAll,
        importXML,
        getPrevisoes,
        previsoes,
      }}
    >
      {children}
    </NotasContext.Provider>
  );
};
