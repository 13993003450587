import React from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  dropFretePorConta,
  dropViaTransporteFrete,
  dropNaturezaFrete,
} from 'utils/drops';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Input from 'components/Input';
import Card from 'components/Card';
import styles from '../styles';

const Transportes = ({
  drops,
  loading,
  onSubmit,
  documento = {},
  disableActions,
}) => {
  const onConfirm = (data) => onSubmit({ Transportes: [data] });

  const Despacho = () => {
    const despacho = documento?.DocumentoTransportes?.find(
      (t) => t?.tipotransp === 'DESPACHO'
    );
    const defaultValues = {
      id: despacho?.id,
      documento_id: documento?.id,
      tipotransp: 'DESPACHO',
      fretepc: despacho?.fretepc || null,
      viatransp: despacho?.viatransp || null,
      natureza: despacho?.natureza || null,
      entidade_id: despacho?.entidade_id || null,
      frota_id: despacho?.frota_id || null,
      motorista_id: despacho?.motorista_id,
      locentrega: despacho?.locentrega || '',
      quantidade: despacho?.quantidade || '',
      marca: despacho?.marca || '',
      especie: despacho?.especie || '',
      numero: despacho?.numero || '',
      volume: despacho?.volume || '',
      tara: despacho?.tara || '',
      pesoliq: despacho?.pesoliq || '',
      pesobrt: despacho?.pesobrt || '',
    };
    const { control, setValue, handleSubmit } = useForm({ defaultValues });

    return (
      <Card title="Despacho" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                setValue('pesoliq', documento?.Totais?.peso || '');
                setValue('pesobrt', documento?.Totais?.peso || '');
              }}
            >
              Calcular Peso
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="fretepc"
              control={control}
              label="Frete por Conta"
              options={dropFretePorConta}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="viatransp"
              control={control}
              label="Via de Transporte"
              options={dropViaTransporteFrete}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="natureza"
              control={control}
              label="Natureza do Frete"
              options={dropNaturezaFrete}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="entidade_id"
              control={control}
              label="Entidade"
              options={drops?.EntidadeTipo?.filter(
                (e) => e?.tipo === 'TRANSPORTADOR'
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="frota_id"
              control={control}
              label="Frota"
              options={drops?.Frota}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="motorista_id"
              control={control}
              label="Motorista"
              options={drops?.EntidadeTipo?.filter(
                (t) => t?.tipo === 'MOTORISTA'
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="locentrega"
              control={control}
              label="Local de Entrega"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="quantidade" control={control} label="Quantidade" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="marca" control={control} label="Marca" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="especie" control={control} label="Espécie" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="numero" control={control} label="Número" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="volume" control={control} label="Volume" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputMask name="tara" control={control} label="Tara" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputMask name="pesoliq" control={control} label="Peso Líquido" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputMask name="pesobrt" control={control} label="Peso Bruto" />
          </Grid>
          <Grid item xs={12} textAlign="center">
            {!disableActions && (
              <Button
                color="primary"
                variant="outlined"
                loading={loading}
                onClick={handleSubmit(onConfirm)}
              >
                Salvar
              </Button>
            )}
          </Grid>
        </Grid>
      </Card>
    );
  };

  const Redespacho = () => {
    const redespacho = documento?.DocumentoTransportes?.find(
      (t) => t?.tipotransp === 'REDESPACHO'
    );
    const defaultValues = {
      id: redespacho?.id,
      documento_id: documento?.id,
      tipotransp: 'REDESPACHO',
      fretepc: redespacho?.fretepc || null,
      viatransp: redespacho?.viatransp || null,
      natureza: redespacho?.natureza || null,
      entidade_id: redespacho?.entidade_id || null,
      frota_id: redespacho?.frota_id || null,
      motorista_id: redespacho?.motorista_id,
      locentrega: redespacho?.locentrega || '',
      quantidade: redespacho?.quantidade || '',
      marca: redespacho?.marca || '',
      especie: redespacho?.especie || '',
      numero: redespacho?.numero || '',
      volume: redespacho?.volume || '',
      tara: redespacho?.tara || '',
      pesoliq: redespacho?.pesoliq || '',
      pesobrt: redespacho?.pesobrt || '',
    };
    const { control, setValue, handleSubmit } = useForm({ defaultValues });

    return (
      <Card title="Redespacho" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                setValue('pesoliq', documento?.Totais?.peso || '');
                setValue('pesobrt', documento?.Totais?.peso || '');
              }}
            >
              Calcular Peso
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="fretepc"
              control={control}
              label="Frete por Conta"
              options={dropFretePorConta}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="viatransp"
              control={control}
              label="Via de Transporte"
              options={dropViaTransporteFrete}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="natureza"
              control={control}
              label="Natureza do Frete"
              options={dropNaturezaFrete}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="entidade_id"
              control={control}
              label="Entidade"
              options={drops?.EntidadeTipo?.filter(
                (t) => t?.tipo === 'TRANSPORTADOR'
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="frota_id"
              control={control}
              label="Frota"
              options={drops?.Frota}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="motorista_id"
              control={control}
              label="Motorista"
              options={drops?.EntidadeTipo?.filter(
                (t) => t?.tipo === 'MOTORISTA'
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="locentrega"
              control={control}
              label="Local de Entrega"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="quantidade" control={control} label="Quantidade" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="marca" control={control} label="Marca" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="especie" control={control} label="Espécie" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="numero" control={control} label="Número" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="volume" control={control} label="Volume" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputMask name="tara" control={control} label="Tara" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputMask name="pesoliq" control={control} label="Peso Líquido" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputMask name="pesobrt" control={control} label="Peso Bruto" />
          </Grid>
          <Grid item xs={12} textAlign="center">
            {!disableActions && (
              <Button
                color="primary"
                variant="outlined"
                loading={loading}
                onClick={handleSubmit(onConfirm)}
              >
                Salvar
              </Button>
            )}
          </Grid>
        </Grid>
      </Card>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Despacho />
      </Grid>
      <Grid item xs={12}>
        <Redespacho />
      </Grid>
    </Grid>
  );
};

export default Transportes;
