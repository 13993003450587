import Card from 'components/Card';
import styles from '../styles';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import TableContainer from 'components/TableContainer';
import moment from 'moment';

const Movimentações = ({ documentoItem = {} }) => {
  return (
    <Card title="Movimentações de Estoque" style={styles?.card}>
      <Grid container spacing={2}>
        {!!documentoItem?.Cemovimentos?.length && (
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Data</TableCell>
                    <TableCell>Produto</TableCell>
                    <TableCell>Local</TableCell>
                    <TableCell>Endereço</TableCell>
                    <TableCell>Variação</TableCell>
                    <TableCell>Rastreabilidade</TableCell>
                    <TableCell>Quantidade</TableCell>
                    <TableCell>Usuário</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documentoItem?.Cemovimentos?.map((item) => (
                    <TableRow key={item?.id?.toString()}>
                      <TableCell>
                        {Boolean(item?.dtmovimento) &&
                          moment(item?.dtmovimento)?.format(
                            'DD/MM/YYYY [às] HH:mm'
                          )}
                      </TableCell>
                      <TableCell>
                        {item?.produto || documentoItem?.descricao}
                      </TableCell>
                      <TableCell>{item?.Celocal?.local}</TableCell>
                      <TableCell>{item?.Ceendereco?.endereco}</TableCell>
                      <TableCell>{item?.ProdutoVariacao?.variacao}</TableCell>
                      <TableCell>{item?.rastreabilidade}</TableCell>
                      <TableCell>{item?.quantidade}</TableCell>
                      <TableCell>{item?.User?.name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Movimentações;
