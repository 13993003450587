import React, { useContext, useEffect } from 'react';
import { Grid, Icon } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DropsContext } from 'contexts/DropsContext';
import { RetornaveisContext } from 'contexts/RetornaveisContext';
import VirtualDrop from 'components/VirtualDrop';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Loader from 'components/Loader';
import Header from 'components/Header';
import Button from 'components/Button';
import Card from 'components/Card';

const Retornáveis = () => {
  const navigate = useNavigate();
  const defaultValues = {
    cadastro_id: null,
    vendedor_id: null,
    filial_id: null,
  };
  const { control, handleSubmit } = useForm({ defaultValues });
  const { drops } = useContext(DropsContext);
  const { retornaveis, getRetornaveis, getLoading } =
    useContext(RetornaveisContext);

  useEffect(() => {
    getRetornaveis();
  }, []);

  return (
    <Container>
      <Header titulo="Retornáveis" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <VirtualDrop
                  name="cadastro_id"
                  control={control}
                  label="Cliente"
                  options={drops?.Entidade}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <VirtualDrop
                  name="filial_id"
                  control={control}
                  label="Filial"
                  options={drops?.Filial}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Dropdown
                  name="vendedor_id"
                  control={control}
                  label="Vendedor"
                  options={drops?.EntidadeTipo?.filter(
                    (f) => f?.tipo === 'VENDEDOR' || f?.tipo === 'REPRESENTANTE'
                  )}
                />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(getRetornaveis)}
                  loading={getLoading}
                >
                  Buscar
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {getLoading ? (
          <Loader />
        ) : (
          <Grid item xs={12}>
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DataGrid
                    rows={retornaveis}
                    columns={[
                      {
                        field: 'actions',
                        headerName: 'Ações',
                        type: 'actions',
                        flex: 1,
                        getActions: ({ row }) => [
                          <GridActionsCellItem
                            icon={<Icon>visibility</Icon>}
                            label="Detalhes"
                            onClick={() =>
                              navigate('/app/Retornaveis/Detalhes', {
                                state: row,
                              })
                            }
                          />,
                        ],
                      },
                      {
                        field: 'cliente',
                        headerName: 'Cliente',
                        flex: 4,
                        sortable: false,
                      },
                      {
                        field: 'filial',
                        headerName: 'Filial',
                        flex: 4,
                        sortable: false,
                      },
                      {
                        field: 'vendedor',
                        headerName: 'Vendedor',
                        flex: 4,
                        sortable: false,
                      },
                      {
                        field: 'quantidade',
                        headerName: 'Quantidade',
                        type: 'number',
                        flex: 2,
                        sortable: false,
                      },
                    ]}
                    hideFooter
                    autoHeight
                    density="compact"
                    disableSelectionOnClick
                    disableColumnMenu
                    showCellRightBorder
                    showColumnRightBorder
                    localeText={{ noRowsLabel: 'Nenhum Registro.' }}
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default Retornáveis;
