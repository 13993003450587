import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { dropNTributado } from 'utils/drops';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { useDialog } from 'components/Modals';
import Card from 'components/Card';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import ButtonMenu from 'components/ButtonMenu';
import TableContainer from 'components/TableContainer';
import styles from '../styles';

const Tributos = ({
  drops,
  onSubmit,
  onDelete,
  loading,
  disableActions,
  onSubmitImposto,
  documentoItem = {},
}) => {
  const { openDialog } = useDialog();
  const rotina = 'DocumentoItemImposto';

  const onConfirm = (values) => {
    let Itens = [
      values
        ? {
            id: documentoItem?.id,
            documento_id: documentoItem?.documento_id,
            Impostos: [values],
          }
        : {
            id: documentoItem?.id,
            documento_id: documentoItem?.documento_id,
          },
    ];

    onSubmit({ Itens });
  };

  const Modal = ({ item }) => {
    const basecalculo =
      (documentoItem?.preco || 0) * (documentoItem?.quantidade || 0) +
      (documentoItem?.desconto_vlr || 0);
    const defaultValues = {
      id: item?.id,
      documento_item_id: item?.documento_item_id,
      tributo_id: item?.tributo_id || null,
      basecalculo: item ? item?.basecalculo || '' : basecalculo || '',
      percentual: item?.percentual || '',
      valor: item?.valor || '',
      situacao_tributaria_id: item?.situacao_tributaria_id || null,
      origem_tributaria_id: item?.origem_tributaria_id || null,
      ntributado: item?.ntributado || null,
      desoneracao_mot: item?.desoneracao_mot || '',
      desoneracao_vlr: item?.desoneracao_vlr || '',
      reducao_pc: item?.reducao_pc || '',
      reducao_vl: item?.reducao_vl || '',
    };
    const { control, handleSubmit, watch, setValue } = useForm({
      defaultValues,
    });
    const { postLoading } = useContext(DocumentosContext);

    useEffect(() => {
      if (Boolean(watch('basecalculo')) && Boolean(watch('percentual'))) {
        const valor =
          ((watch('basecalculo') || 0) * watch('percentual') || 0) / 100;
        setValue('valor', Number(valor.toFixed(2)));
      }
    }, [watch('basecalculo'), watch('percentual')]);

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Dropdown
            name="tributo_id"
            control={control}
            label="Tributo"
            options={drops?.Tributo}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask
            name="basecalculo"
            control={control}
            label="Base de Cálculo"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask name="percentual" control={control} label="Alíquota" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask name="valor" control={control} label="Valor" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="situacao_tributaria_id"
            control={control}
            label="Situação Tributária"
            options={drops?.SituacaoTributaria?.filter(
              (f) => f?.tributo_id === watch('tributo_id')
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="origem_tributaria_id"
            control={control}
            label="Origem Tributária"
            options={drops?.OrigemTributaria}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="ntributado"
            control={control}
            label="Não Tributado"
            options={dropNTributado}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            name="desoneracao_mot"
            control={control}
            label="Motivo da Desoneração"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            name="desoneracao_vlr"
            control={control}
            label="Valor da Desoneração"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask name="reducao_pc" control={control} label="% da Redução" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            name="reducao_vl"
            control={control}
            label="Valor da Redução"
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onConfirm)}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const options = [
    {
      name: 'Adicionar Tributo',
      icon: 'add',
      action: () => openDialog(<Modal />, 'Adicionar Tributo'),
    },
    {
      name: 'Recarregar Tributos',
      icon: 'refresh',
      action: () => onSubmitImposto([documentoItem?.id]),
    },
  ];

  return (
    <Card title="Tributos" style={styles?.card}>
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <ButtonMenu
              buttonProps={{ variant: 'outlined', loading }}
              options={options}
            />
          </Grid>
        )}
        {!!documentoItem?.DocumentoItemImpostos?.length && (
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Imposto</TableCell>
                    <TableCell>Base de Cálculo</TableCell>
                    <TableCell>Alíquota</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Situação Tributária</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documentoItem?.DocumentoItemImpostos?.map((item) => (
                    <TableRow key={item?.id?.toString()}>
                      <TableCell>{item?.Tributo?.descricao}</TableCell>
                      <TableCell>
                        {item?.basecalculo?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </TableCell>
                      <TableCell>
                        {item?.percentual && `${item?.percentual}%`}
                      </TableCell>
                      <TableCell>
                        {item?.valor?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </TableCell>
                      <TableCell>
                        {item?.SituacaoTributarium?.descricao}
                      </TableCell>
                      <TableCell align="center">
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() =>
                              openDialog(
                                <Modal item={item} />,
                                'Editar Tributo'
                              )
                            }
                          >
                            <Edit />
                          </IconButton>
                        )}
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() => onDelete({ rotina, id: item?.id })}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Tributos;
