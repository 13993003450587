const styles = {
  semiCard: {
    width: '100%',
  },
  image: {
    objectFit: 'contain',
    maxHeight: '150px',
    overflow: 'hidden',
    width: '100%',
  },
  detailsProps: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textDetailsProps: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
};

export default styles;
