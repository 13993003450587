import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Grid,
  IconButton,
  MobileStepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  AttachFile,
  Delete,
  Edit,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  PictureAsPdf,
} from '@mui/icons-material';
import { AuthContext } from 'contexts/AuthContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { useDialog } from 'components/Modals';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import InputMask from 'components/InputMask';
import TableContainer from 'components/TableContainer';
import styles from '../styles';
import moment from 'moment';

const Registros = ({
  onSubmit,
  onDelete,
  onSubmitAnexo,
  onDeleteAnexo,
  documento = {},
}) => {
  const { openDialog } = useDialog();
  const rotina = 'DocumentoHist';
  const { user } = useContext(AuthContext);

  const Modal = ({ item }) => {
    const defaultValues = {
      id: item?.id,
      documento_id: documento?.id,
      users_id: user?.id,
      datahora: moment().format('YYYY-MM-DD HH:mm:ss'),
      historico: item?.historico || '',
      inicio: item?.inicio
        ? moment(item?.inicio).format('YYYY-MM-DD HH:mm:ss')
        : null,
      fim: item?.fim ? moment(item?.fim).format('YYYY-MM-DD HH:mm:ss') : null,
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(DocumentosContext);

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Input name="historico" control={control} label="Registro" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            name="inicio"
            control={control}
            label="Início"
            type="datetime"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask name="fim" control={control} label="Fim" type="datetime" />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit((data) => onSubmit({ Registros: [data] }))}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const AnexoModal = ({ item }) => {
    const [activeStep, setActiveStep] = useState(0);
    const { postLoading, deleteLoading } = useContext(DocumentosContext);

    const handleNext = () => setActiveStep((prev) => prev + 1);

    const handleBack = () => setActiveStep((prev) => prev - 1);

    const handleStepChange = (step) => setActiveStep(step);

    const onConfirmMidia = (file) => {
      const formData = new FormData();
      formData.append('id', item?.id);
      formData.append('midia', file);
      onSubmitAnexo(formData);
    };

    const RenderItem = ({ item }) => {
      if (item?.Midium?.tipo === 'IMAGEM') {
        return (
          <Box component="img" sx={styles.image} src={item?.Midium?.url} />
        );
      }
      if (item?.Midium?.tipo === 'PDF') {
        return (
          <Box sx={styles.pdf}>
            <IconButton
              size="small"
              onClick={() => window.open(item?.Midium?.url)}
            >
              <PictureAsPdf fontSize="large" color="action" />
            </IconButton>
          </Box>
        );
      }
      return null;
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {!item?.DocumentoHistmids?.length ? (
            <Typography variant="h6" component="div" align="center">
              Nenhum anexo encontrado
            </Typography>
          ) : (
            <Box sx={{ flexGrow: 1 }}>
              <SwipeableViews
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
                {item?.DocumentoHistmids?.map((a, index) => (
                  <div key={a?.id?.toString()}>
                    {Math.abs(activeStep - index) <= 2 && (
                      <RenderItem item={a} />
                    )}
                  </div>
                ))}
              </SwipeableViews>
              <MobileStepper
                steps={item?.DocumentoHistmids?.length}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={handleNext}
                    disabled={
                      activeStep === item?.DocumentoHistmids?.length - 1
                    }
                  >
                    Próximo
                    <KeyboardArrowRight />
                  </Button>
                }
                backButton={
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    <KeyboardArrowLeft />
                    Anterior
                  </Button>
                }
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} textAlign="center">
          {!!item?.DocumentoHistmids?.length && (
            <Button
              variant="contained"
              color="secondary"
              loading={deleteLoading}
              sx={styles.button}
              onClick={() =>
                onDeleteAnexo(item?.DocumentoHistmids[activeStep]?.midia_id)
              }
            >
              Excluir
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            sx={styles.button}
            onClick={() => document.getElementById('upload-midia')?.click()}
            loading={postLoading}
          >
            <input
              id="upload-midia"
              type="file"
              hidden
              onChange={({ target }) => {
                onConfirmMidia(target.files[0]);
                target.value = '';
              }}
            />
            Adicionar
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Card title="Registros" style={styles?.card}>
      <Grid container spacing={2}>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => openDialog(<Modal />, 'Adicionar Registro')}
          >
            Adicionar
          </Button>
        </Grid>
        {!!documento?.DocumentoHists?.length && (
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Registro</TableCell>
                    <TableCell>Entidade</TableCell>
                    <TableCell>Data / Hora</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documento?.DocumentoHists?.map((item) => (
                    <TableRow key={item?.id?.toString()}>
                      <TableCell>{item?.historico}</TableCell>
                      <TableCell>{item?.User?.name}</TableCell>
                      <TableCell>
                        {Boolean(item?.datahora) &&
                          moment(item?.datahora).isValid() &&
                          moment(item?.datahora).format(
                            'DD/MM/YYYY [às] HH:mm'
                          )}
                      </TableCell>
                      {Boolean(item?.status_id) ? (
                        <TableCell />
                      ) : (
                        <TableCell align="center">
                          <IconButton
                            size="small"
                            onClick={() =>
                              openDialog(<AnexoModal item={item} />, 'Anexos')
                            }
                          >
                            <AttachFile />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() =>
                              openDialog(
                                <Modal item={item} />,
                                'Editar Registro'
                              )
                            }
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() => onDelete({ rotina, id: item?.id })}
                          >
                            <Delete />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Registros;
