import React from 'react';
import { Grid } from '@mui/material';
import { dropStatusDocumento, dropOperacao } from 'utils/drops';
import Input from 'components/Input';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';

const Filter = ({ grid, control, drops }) => {
  const checkDisplay = (name) => {
    const column = grid?.colunas?.find((c) => c?.field === name);
    if (column?.filterable) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2}>
      {checkDisplay('id') && (
        <Grid item xs={12}>
          <Input name="id" control={control} label="ID" type="number" />
        </Grid>
      )}
      {checkDisplay('status') && (
        <Grid item xs={12}>
          <Dropdown
            name="status"
            control={control}
            label="Status"
            options={dropStatusDocumento}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('situacao') && (
        <Grid item xs={12}>
          <Dropdown
            name="situacao"
            control={control}
            label="Situação"
            options={drops?.StatusDocumento}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('dtemissao') && (
        <Grid item xs={6}>
          <InputMask
            name="dtemissao.i"
            control={control}
            label="Data de Emissão Inicial"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('dtemissao') && (
        <Grid item xs={6}>
          <InputMask
            name="dtemissao.f"
            control={control}
            label="Data de Emissão Final"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('operacao') && (
        <Grid item xs={12}>
          <Dropdown
            name="operacao"
            control={control}
            label="Operação"
            options={dropOperacao}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('requisitante') && (
        <Grid item xs={12}>
          <Dropdown
            name="requisitante"
            control={control}
            label="Requisitante"
            options={drops?.Users}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('origem') && (
        <Grid item xs={12}>
          <Input name="origem" control={control} label="Origem" />
        </Grid>
      )}
    </Grid>
  );
};

export default Filter;
