import React, { useContext, useState, useEffect } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { AllInbox, Delete, QrCode } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { checkInfosProduto, findOnArray } from 'utils/functions';
import { toastWarning } from 'utils/toast';
import { AuthContext } from 'contexts/AuthContext';
import { DropsContext } from 'contexts/DropsContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import Container from 'components/Container';
import VirtualDrop from 'components/VirtualDrop';
import TableContainer from 'components/TableContainer';
import Blank from 'assets/blank.png';
import styles from './styles';
import moment from 'moment';

const Inventário = () => {
  let timer;
  const waitTime = 1000;
  const { user } = useContext(AuthContext);
  const defaultValues = {
    filial_id: user?.filial_id,
    celocal_id: null,
    dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
    //ITENS
    produto_id: null,
    cdbarra: '',
    quantidade: '',
    itens: [],
  };
  const detailsProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  const textDetailsProps = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  };
  const [loading, setLoading] = useState(false);
  const [additionals, setAdditionals] = useState(null);
  const [useBarCode, setUserBarCode] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    resetField,
    getValues,
    reset,
  } = useForm({
    defaultValues,
  });
  const { drops } = useContext(DropsContext);
  const { postLoading, postDocumentos } = useContext(DocumentosContext);

  useEffect(() => {
    if (Boolean(watch('produto_id'))) {
      getAdditionals({
        submit: Boolean(watch('cdbarra')) && Boolean(watch('produto_id')),
      });
    } else {
      resetField('cdbarra');
      resetField('quantidade');
      setAdditionals(null);
    }
  }, [watch('produto_id')]);

  const getAdditionals = async ({ submit }) => {
    setLoading(true);
    const produto = await checkInfosProduto({
      produto_id: watch('produto_id'),
      tipo_saldo: 'FISICO',
      tempo_saldo:
        watch('dtemissao') &&
        moment(watch('dtemissao')).format('YYYY-MM-DD HH:mm:ss'),
      filial_id: watch('filial_id'),
      local_id: watch('celocal_id'),
    });
    const descricao = findOnArray(produto?.id, drops?.Produto, 'label');
    const full_prod = { ...produto, descricao };
    setAdditionals(full_prod);
    if (!Boolean(watch('quantidade'))) {
      setValue('quantidade', full_prod?.volume || 1);
    }
    if (submit) {
      onAddItem({ ...full_prod, ...getValues() });
    }
    setLoading(false);
  };

  const onAddItem = (values) => {
    if (!Boolean(values?.produto_id)) {
      toastWarning('Nenhum produto selecionado');
    } else {
      if (values?.itens.some((s) => s?.produto_id === values?.produto_id)) {
        toastWarning('Produto já inserido');
      } else {
        setValue('itens', [
          {
            ...additionals,
            produto_id: values?.produto_id,
            quantidade: values?.quantidade,
            ajuste: (values?.quantidade || 0) - (additionals?.saldo || 0),
          },
          ...values?.itens,
        ]);
        resetField('produto_id');
      }
    }
  };

  const onDeleteItem = (index) => {
    setValue(
      'itens',
      watch('itens')?.filter((_, i) => i !== index)
    );
  };

  const onSubmit = (values) => {
    const itensEntrada = values?.itens
      ?.filter((f) => f?.ajuste > 0)
      ?.map((i, index) => ({
        sequencia: index + 1,
        produto_id: i?.produto_id,
        descricao: i?.descricao,
        quantidade: Math.abs(i?.ajuste),
        dhsaldo: values?.dtemissao,
      }));
    const itensSaida = values?.itens
      ?.filter((f) => f?.ajuste < 0)
      ?.map((i, index) => ({
        sequencia: index + 1,
        produto_id: i?.produto_id,
        descricao: i?.descricao,
        quantidade: Math.abs(i?.ajuste),
        dhsaldo: values?.dtemissao,
      }));
    const data = [];

    if (Boolean(itensEntrada?.length)) {
      data?.push({
        Documento: {
          especie_id: 39,
          natureza_operacao_id: 17,
          users_id: user?.id,
          filial_id: values?.filial_id,
          cadastro_id: values?.filial_id,
          celocal_id: values?.celocal_id,
          dtemissao: values?.dtemissao,
          conferido: 'SIM',
        },
        Itens: itensEntrada,
      });
    }
    if (Boolean(itensSaida?.length)) {
      data?.push({
        Documento: {
          especie_id: 39,
          natureza_operacao_id: 28,
          users_id: user?.id,
          filial_id: values?.filial_id,
          cadastro_id: values?.filial_id,
          celocal_id: values?.celocal_id,
          dtemissao: values?.dtemissao,
          conferido: 'SIM',
        },
        Itens: itensSaida,
      });
    }
    if (Boolean(data?.length)) {
      postDocumentos({
        data,
        cb: () => {
          reset();
          setValue('dtemissao', moment().format('YYYY-MM-DD HH:mm:ss'));
        },
      });
    } else {
      toastWarning('Nenhum ajuste necessário a ser efetuado');
    }
  };

  return (
    <Container>
      <Header titulo="Inventário" />
      <Card title="Informações Gerais">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Dropdown
              name="filial_id"
              control={control}
              label="Filial"
              options={drops?.Filial}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="celocal_id"
              control={control}
              label="Local de Estoque"
              options={drops?.LocalEstoque?.filter(
                (f) =>
                  !Boolean(f?.filial_id) || f?.filial_id === watch('filial_id')
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask
              name="dtemissao"
              control={control}
              label="Data de Contagem"
              type="datetime"
            />
          </Grid>
        </Grid>
      </Card>
      <Card title="Itens" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} display="flex">
            <Card style={styles?.semiCard}>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  {Boolean(useBarCode) ? (
                    <Input
                      name="cdbarra"
                      control={control}
                      label="Código de Barras"
                      onKeyUp={(e) => {
                        clearTimeout(timer);
                        timer = setTimeout(() => {
                          const value = e?.target?.value;
                          if (Boolean(value)) {
                            const produto = drops?.Produto.find(
                              (f) => f?.cdbarra == e?.target?.value
                            );
                            setValue('produto_id', produto?.value || null);
                          }
                        }, waitTime);
                      }}
                    />
                  ) : (
                    <VirtualDrop
                      name="produto_id"
                      control={control}
                      label="Produto"
                      options={drops?.Produto}
                      refresh="Produto"
                    />
                  )}
                </Grid>
                <Grid item xs={2} textAlign="center">
                  <IconButton
                    size="small"
                    onClick={() => setUserBarCode(!useBarCode)}
                  >
                    {Boolean(useBarCode) ? (
                      <AllInbox color="primary" />
                    ) : (
                      <QrCode color="primary" />
                    )}
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <InputMask
                    name="quantidade"
                    control={control}
                    label="Quantidade"
                  />
                </Grid>
                <Grid item xs={12} sm={6} {...detailsProps}>
                  <Box
                    component="img"
                    sx={styles?.image}
                    src={additionals?.imagem || Blank}
                  />
                </Grid>
                {Boolean(loading) ? (
                  <Grid item xs={12} sm={6} {...detailsProps}>
                    <Loader />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={6} {...textDetailsProps}>
                    <Typography variant="h6" color="primary">
                      {additionals?.descricao}
                    </Typography>
                    <Typography variant="body1">
                      Referência: {additionals?.referencia || '-'}
                    </Typography>
                    <Typography variant="body1">
                      Saldo: {additionals?.saldo || '-'}{' '}
                      {additionals?.UnidadeMedida?.descricao || ''}
                    </Typography>
                    <Typography variant="body1">
                      Localização: {additionals?.localizador || '-'}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} textAlign="center">
                  <Button
                    variant="contained"
                    color="primary"
                    loading={loading}
                    onClick={handleSubmit(onAddItem)}
                  >
                    ADICIONAR
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} display="flex">
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Referência</TableCell>
                          <TableCell>Descrição</TableCell>
                          <TableCell>Saldo Atual</TableCell>
                          <TableCell>Saldo Contagem</TableCell>
                          <TableCell>Ajuste</TableCell>
                          <TableCell align="center">Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {watch('itens')?.map((item, index) => (
                          <TableRow key={index?.toString()}>
                            <TableCell>{item?.referencia}</TableCell>
                            <TableCell>{item?.descricao}</TableCell>
                            <TableCell>{item?.saldo}</TableCell>
                            <TableCell>{item?.quantidade}</TableCell>
                            <TableCell>{item?.ajuste}</TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={() => onDeleteItem(index)}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Card>
      <Grid item xs={12} textAlign="center" sx={styles?.button}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
      </Grid>
    </Container>
  );
};

export default Inventário;
