import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { dropBoolean } from 'utils/drops';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { useDialog } from 'components/Modals';
import Card from 'components/Card';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import ButtonMenu from 'components/ButtonMenu';
import VirtualDrop from 'components/VirtualDrop';
import TableContainer from 'components/TableContainer';
import styles from '../styles';
import moment from 'moment';

const Estrutura = ({
  drops,
  onSubmit,
  onDelete,
  loading,
  disableActions,
  onSubmitEstrutura,
  documentoItem = {},
}) => {
  const { openDialog } = useDialog();
  const rotina = 'DocumentoItemEstrutura';

  const onConfirm = (values) => {
    const data = {
      Itens: [
        {
          id: documentoItem?.id,
          documento_id: documentoItem?.documento_id,
          Estruturas: [values],
        },
      ],
    };

    onSubmit(data);
  };

  const Modal = ({ item }) => {
    const defaultValues = {
      id: item?.id,
      documento_item_id: item?.documento_item_id,
      componente_id: item?.componente_id || null,
      operacao: item?.operacao || '',
      sequencia: item?.sequencia || '',
      quantidade: item?.quantidade || '',
      perda: item?.perda || '',
      volume: item?.volume || '',
      retornavel: item?.retornavel || null,
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(DocumentosContext);

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <VirtualDrop
            name="componente_id"
            control={control}
            label="Componente"
            options={drops?.Produto}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="operacao" control={control} label="Operação" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="sequencia" control={control} label="Sequência" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="quantidade" control={control} label="Quantidade" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="perda" control={control} label="% Perda" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="volume" control={control} label="Volume" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="retornavel"
            control={control}
            label="Retornável"
            options={dropBoolean}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onConfirm)}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const options = [
    {
      name: 'Adicionar Estrutura',
      icon: 'add',
      action: () => openDialog(<Modal />, 'Adicionar Estrutura'),
    },
    {
      name: 'Recarregar Estrutura',
      icon: 'refresh',
      action: () => onSubmitEstrutura({ id: documentoItem?.id }),
    },
  ];

  return (
    <Card title="Estrutura" style={styles?.card}>
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <ButtonMenu
              buttonProps={{ variant: 'outlined', loading }}
              options={options}
            />
          </Grid>
        )}
        {!!documentoItem?.DocumentoItemEstruturas?.length && (
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Sequência</TableCell>
                    <TableCell>Componente</TableCell>
                    <TableCell>Perda</TableCell>
                    <TableCell>Quantidade</TableCell>
                    <TableCell>Custo Unitário</TableCell>
                    <TableCell>Custo Total</TableCell>
                    <TableCell>Atualizado em</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documentoItem?.DocumentoItemEstruturas?.map((item) => (
                    <TableRow key={item?.id?.toString()}>
                      <TableCell>{item?.sequencia}</TableCell>
                      <TableCell>
                        {
                          item?.Produto?.ProdutoDescricaos?.find(
                            (f) =>
                              f?.tipo === 'COMPLETA' || f?.tipo === 'REDUZIDA'
                          )?.descricao
                        }
                      </TableCell>
                      <TableCell>{item?.perda && `${item?.perda}%`}</TableCell>
                      <TableCell>
                        {item?.quantidade} {item?.Produto?.UnidadeMedida?.sigla}
                      </TableCell>
                      <TableCell>
                        {item?.custo_unitario?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </TableCell>
                      <TableCell>
                        {item?.custo_total?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </TableCell>
                      <TableCell>
                        {Boolean(item?.atualizado) &&
                          moment(item?.atualizado).isValid() &&
                          moment(item?.atualizado).format(
                            'DD/MM/YYYY [às] HH:mm'
                          )}
                      </TableCell>
                      <TableCell align="center">
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() =>
                              openDialog(
                                <Modal item={item} />,
                                'Editar Estrutura'
                              )
                            }
                          >
                            <Edit />
                          </IconButton>
                        )}
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() => onDelete({ rotina, id: item?.id })}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Estrutura;
