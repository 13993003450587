import zIndex from '@mui/material/styles/zIndex';

const styles = {
  card: {
    flex: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    height: 200,
  },
  fab: {
    position: 'absolute',
    top: 10,
    right: 10,
    transform: 'scale(0.7)',
  },
  fabContainer: {
    position: 'fixed',
    bottom: 20,
    right: 40,
  },
  badge: {
    zIndex: 1,
  },
  tooltip: {
    mx: 1,
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  inputProps: {
    disableUnderline: true,
    sx: {
      '& .MuiInputBase-root': {
        padding: 0,
        border: 'none',
        fontSize: 'inherit',
        background: 'none',
        boxShadow: 'none',
      },
      '& .MuiInput-underline:before': {
        borderBottom: 'none',
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 'none',
      },
      '& .MuiInput-underline:after': {
        borderBottom: 'none',
      },
      '& .MuiInputBase-input': {
        padding: 0,
        textAlign: 'center',
        width: '50px',
      },
      '& .MuiInput-input': {
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
        },
      },
      '& .MuiOutlinedInput-input': {
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
        },
      },
    },
  },
  slotProps: {
    paper: {
      sx: {
        width: 200,
        maxHeight: 300,
        p: 1,
      },
    },
  },
};

export default styles;
