import { useContext } from 'react';
import { DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { findOnArray } from 'utils/functions';
import { toastWarning } from 'utils/toast';
import { DropsContext } from 'contexts/DropsContext';
import { useModal } from 'components/Modals';
import Button from 'components/Button';
import VirtualDrop from 'components/VirtualDrop';
import InputMask from 'components/InputMask';

const CompraModal = ({ item = null, onSubmit }) => {
  const defaultValues = {
    cadastro_id: null,
    filial_id: item?.filial_id,
    quantidade: item?.quantidade || '0',
    preco: item?.preco || '0',
  };
  const { drops } = useContext(DropsContext);
  const { control, handleSubmit } = useForm({ defaultValues });
  const { closeModal } = useModal();

  const onConfirm = (values) => {
    if (
      !Boolean(values?.quantidade) ||
      !Boolean(values?.preco) ||
      !Boolean(values?.cadastro_id)
    ) {
      return toastWarning('Preencha todos os campos');
    }
    const cadastro = findOnArray(values?.cadastro_id, drops.Entidade, 'label');
    const data = { ...item, ...values, cadastro };
    onSubmit(data);
    closeModal();
  };

  return (
    <>
      <DialogTitle>Informações da Compra</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <VirtualDrop
              name="cadastro_id"
              control={control}
              label="Fornecedor"
              options={drops?.Entidade}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask name="quantidade" control={control} label="Quantidade" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask name="preco" control={control} label="Preço" />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onConfirm)}
        >
          CONFIRMAR
        </Button>
        <Button variant="contained" color="secondary" onClick={closeModal}>
          VOLTAR
        </Button>
      </DialogActions>
    </>
  );
};

export default CompraModal;
