import { createContext, useState } from 'react';
import api from 'services/api';

export const RelatoriosContext = createContext();

export const RelatoriosProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [relatorio, setRelatorio] = useState(null);
  const [relatorios, setRelatorios] = useState([]);

  const clearRelatorio = () => setRelatorio(null);

  const postRelatorio = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      if (data) {
        await api.post('/Grid/Mail', data);
      }
    } catch (error) {
    } finally {
      if (cb) {
        cb();
      }
      setPostLoading(false);
    }
  };

  const getURLRelatorio = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      const res = await api.post(`/Relatorios/Gerar`, data);
      setRelatorio(res?.data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const getURLEmissao = async ({ data: params, cb }) => {
    try {
      setPostLoading(true);
      const res = await api.get(`/Relatorios/Gerar/Documento`, { params });
      setRelatorio(res?.data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const getRelatorios = async () => {
    try {
      const params = { modulo: 'SUPRIMENTOS' };
      setGetLoading(true);
      const { data } = await api.get(`/Relatorios`, { params });
      setRelatorios(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <RelatoriosContext.Provider
      value={{
        getURLRelatorio,
        getURLEmissao,
        getRelatorios,
        postRelatorio,
        clearRelatorio,
        getLoading,
        postLoading,
        relatorio,
        relatorios,
      }}
    >
      {children}
    </RelatoriosContext.Provider>
  );
};
