import React, { useContext, useEffect } from 'react';
import { Grid, Icon, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useForm } from 'react-hook-form';
import { summarizer } from 'utils/functions';
import { useLocation, useNavigate } from 'react-router-dom';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { AuthContext } from 'contexts/AuthContext';
import Container from 'components/Container';
import Button from 'components/Button';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';
import styles from './styles';
import moment from 'moment';

const Perda = () => {
  const { state } = useLocation();
  const { user } = useContext(AuthContext);
  const defaultValues = {
    observacao: '',
    dadosadc: '',
    itens: [],
  };
  const navigate = useNavigate();
  const { postDocumento, postLoading } = useContext(DocumentosContext);
  const { control, handleSubmit, watch, setValue } = useForm({ defaultValues });

  useEffect(() => {
    if (Boolean(state)) {
      setValue('itens', state?.itens || []);
    } else {
      navigate(-1);
    }
  }, []);

  const onDeleteItem = (params) => {
    setValue(
      'itens',
      watch('itens')?.filter((r) => r?.id !== params?.id)
    );
  };

  const onCellEditCommit = (e) => {
    const editedRows = watch('itens')?.map((c) => {
      if (c?.id === e?.id && e?.field === 'quantidade') {
        if (e?.value > c?.quantidade_max) {
          const subtotal = (c?.quantidade_max || 0) * (c?.preco || 0);
          return { ...c, [e?.field]: c?.quantidade_max, subtotal };
        } else {
          const subtotal = (e?.value || 0) * (c?.preco || 0);
          return { ...c, [e?.field]: e?.value, subtotal };
        }
      } else {
        return { ...c };
      }
    });
    setValue('itens', editedRows);
  };

  const onSubmit = (values) => {
    const data = {
      Documento: {
        especie_id: state?.especie_id,
        natureza_operacao_id: 53,
        users_id: user?.id,
        filial_id: state?.filial_id,
        cadastro_id: state?.cadastro_id,
        dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
        observacao: values?.observacao,
        dadosadc: values?.dadosadc,
      },
      Saida: {
        vendedor_id: state?.vendedor_id,
      },
      Itens: values?.itens?.map((i, index) => ({
        sequencia: index + 1,
        produto_id: i?.produto_id,
        descricao: i?.descricao,
        referencia: i?.referencia,
        quantidade: i?.quantidade,
        preco: i?.preco,
        Origens: [{ documento_item_org_id: i?.origem_id }],
      })),
    };
    postDocumento({ data, cb: () => navigate(-1) });
  };

  return (
    <Container>
      <Header
        titulo={
          state?.especie_id === 10
            ? 'Cadastrar Nota de Perda / Refugo'
            : 'Cadastrar Fatura de Perda / Refugo'
        }
      />
      <Card title="Informações Adicionais">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Input
              name="observacao"
              control={control}
              label="Observação"
              multiline
              rows={5}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="dadosadc"
              control={control}
              label="Dados Adicionais"
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </Card>
      <Card title="Itens" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DataGrid
                    rows={watch('itens')}
                    columns={[
                      {
                        field: 'referencia',
                        headerName: 'Referência',
                        flex: 1,
                        sortable: false,
                      },
                      {
                        field: 'descricao',
                        headerName: 'Produto',
                        flex: 4,
                        sortable: false,
                      },
                      {
                        field: 'quantidade',
                        headerName: 'Quantidade',
                        type: 'number',
                        flex: 1,
                        editable: true,
                        sortable: false,
                      },
                      {
                        field: 'preco',
                        headerName: 'Preço Unitário',
                        type: 'number',
                        valueGetter: ({ value }) =>
                          value &&
                          value?.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          }),
                        flex: 1,
                        sortable: false,
                      },
                      {
                        field: 'subtotal',
                        headerName: 'Subtotal',
                        type: 'number',
                        valueGetter: ({ value }) =>
                          value &&
                          value?.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          }),
                        flex: 1,
                        sortable: false,
                      },
                      {
                        field: 'actions',
                        headerName: 'Ações',
                        type: 'actions',
                        flex: 1,
                        getActions: (params) => [
                          <GridActionsCellItem
                            icon={<Icon>delete</Icon>}
                            label="Excluir"
                            onClick={() => onDeleteItem(params)}
                          />,
                        ],
                      },
                    ]}
                    hideFooter
                    autoHeight
                    density="compact"
                    disableSelectionOnClick
                    disableColumnMenu
                    showCellRightBorder
                    showColumnRightBorder
                    localeText={{ noRowsLabel: 'Nenhum Registro.' }}
                    onCellEditCommit={onCellEditCommit}
                  />
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Typography variant="h6" color="primary">
                    Total:{' '}
                    {summarizer(watch('itens'), 'subtotal')?.toLocaleString(
                      'pt-br',
                      {
                        style: 'currency',
                        currency: 'BRL',
                      }
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Card>
      <Grid item xs={12} textAlign="center" sx={styles?.button}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
      </Grid>
    </Container>
  );
};

export default Perda;
