import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  InputAdornment,
  LinearProgress,
  Pagination,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { Search } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { decryptURL, filterSearch } from 'utils/functions';
import { toastWarning } from 'utils/toast';
import { DropsContext } from 'contexts/DropsContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import Card from 'components/Card';
import Input from 'components/Input';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Button from 'components/Button';
import Container from 'components/Container';
import VirtualDrop from 'components/VirtualDrop';
import localeText from 'utils/localeText';
import moment from 'moment';

const Vinculo = () => {
  const { hash } = useParams();
  const navigate = useNavigate();
  const decrypt = decryptURL(hash);
  const defaultValues = {
    cadastro_id: decrypt?.cadastro_id,
    filial_id: decrypt?.filial_id,
    search: '',
  };
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const { control, handleSubmit, watch } = useForm({ defaultValues });
  const { drops } = useContext(DropsContext);
  const {
    getLoading,
    getDocumentoVinculos,
    getDocumentoOrigens,
    postDocumento,
    postLoading,
  } = useContext(DocumentosContext);

  const loadData = (params) => {
    if (!Boolean(decrypt)) {
      return navigate(-1);
    }
    if (Boolean(decrypt?.vinculo)) {
      return getDocumentoVinculos({ params, cb: setData });
    } else {
      return getDocumentoOrigens({ params, cb: setData });
    }
  };

  const onSubmit = (values) => {
    const key = Boolean(decrypt?.vinculo)
      ? 'documento_item_id_vinculo'
      : 'documento_item_org_id';
    const name = Boolean(decrypt?.vinculo) ? 'Vinculos' : 'Origens';
    const arr = selected?.map((s) => ({
      documento_item_id: decrypt?.id,
      [key]: s?.id,
    }));
    const Itens = [
      { id: decrypt?.id, documento_id: decrypt?.documento_id, [name]: arr },
    ];
    const Origens = [...new Set(selected?.map((i) => i?.documento_id))]?.map(
      (documento_id) => ({ documento_id })
    );
    const data = { Itens, Origens };
    if (Boolean(arr?.length)) {
      postDocumento({ data, cb: () => navigate(-1) });
    } else {
      toastWarning('Selecione ao menos um item');
    }
  };

  useEffect(() => {
    handleSubmit(loadData)();
  }, []);

  if (getLoading) {
    return <Loader />;
  }

  const CustomPagination = () => {
    const gridRef = useGridApiContext();
    const count = useGridSelector(gridRef, gridPageCountSelector);
    return (
      <Pagination
        color="primary"
        count={count}
        onChange={(_, value) => gridRef?.current?.setPage(value - 1)}
      />
    );
  };

  const DocumentoCell = ({ row }) => {
    const lines = [
      { label: 'Nº do Documento', value: row?.documento },
      {
        label: 'Data de Emissão',
        value:
          row?.dtemissao &&
          moment(row?.dtemissao).format('DD/MM/YYYY [às] HH:mm'),
      },
    ];

    return (
      <Box flex={1}>
        <Typography variant="body2" fontWeight="bold" mb={1}>
          {row?.especie || ''}
        </Typography>
        {lines?.map(
          ({ label, value }) =>
            Boolean(value) && (
              <Box display="flex" alignItems="center" flex={1}>
                <Typography variant="caption" fontWeight="bold" flex={1}>
                  {label}:{' '}
                </Typography>
                <Typography variant="caption" flex={2}>
                  {value}
                </Typography>
              </Box>
            )
        )}
      </Box>
    );
  };

  return (
    <Container>
      <Header
        titulo={`Vincular ${decrypt?.descricao}`}
        subtitulo={`${decrypt?.quantidade || 0} ${decrypt?.um || ''}`}
      />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <VirtualDrop
              name="cadastro_id"
              control={control}
              label="Entidade"
              options={drops?.Entidade}
              onValueChange={handleSubmit(loadData)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="search"
              placeholder="Buscar..."
              control={control}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onKeyPress={(e) => e?.key === 'Enter' && handleSubmit(loadData)()}
            />
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              rows={filterSearch(data, watch('search'))}
              columns={[
                {
                  field: 'descricao',
                  headerName: 'Descrição',
                  width: 500,
                  sortable: false,
                },
                {
                  field: 'quantidade',
                  headerName: 'Quantidade',
                  type: 'number',
                  width: 150,
                  sortable: false,
                  valueGetter: ({ row }) =>
                    `${row?.quantidade || 0} ${row?.um || ''}`,
                },
                {
                  field: 'documento',
                  headerName: 'Documento',
                  width: 500,
                  sortable: false,
                  renderCell: ({ row }) => <DocumentoCell row={row} />,
                },
              ]}
              autoHeight
              pagination
              pageSize={10}
              density="compact"
              loading={getLoading}
              disableColumnMenu
              checkboxSelection
              showCellRightBorder
              showColumnRightBorder
              disableSelectionOnClick
              localeText={localeText}
              keepNonExistentRowsSelected
              getRowHeight={() => 'auto'}
              selectionModel={selected?.map((s) => s?.id)}
              onSelectionModelChange={(ids) =>
                setSelected(ids?.map((id) => data?.find((f) => f?.id === id)))
              }
              components={{
                LoadingOverlay: LinearProgress,
                Pagination: CustomPagination,
              }}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              loading={postLoading}
            >
              Vincular
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Vinculo;
