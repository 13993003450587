import React, { useContext, useState } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import { ManifestacoesContext } from 'contexts/ManifestacoesContext';
import { useModal } from 'components/Modals';
import Button from 'components/Button';

const TipoImportacaoModal = ({ id, onSubmit }) => {
  const { closeModal } = useModal();
  const { postLoading } = useContext(ManifestacoesContext);
  const [produto, setProduto] = useState(false);
  const [transportador, setTransp] = useState(false);

  return (
    <>
      <DialogTitle>Configurações para importação do XML</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} display="flex" alignItems="center">
            <Switch checked={produto} onChange={(_, v) => setProduto(v)} />
            <Typography>Cadastramento automático de produtos</Typography>
          </Grid>
          <Grid item xs={12} md={6} display="flex" alignItems="center">
            <Switch checked={transportador} onChange={(_, v) => setTransp(v)} />
            <Typography>Cadastramento automático do transportador</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          loading={postLoading}
          onClick={() => onSubmit({ produto, transportador, id })}
        >
          Confirmar
        </Button>
        <Button onClick={closeModal} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default TipoImportacaoModal;
