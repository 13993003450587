import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  Radio,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { AuthContext } from 'contexts/AuthContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { toastWarning } from 'utils/toast';
import { useModal } from 'components/Modals';
import Container from 'components/Container';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Button from 'components/Button';
import Card from 'components/Card';
import Input from 'components/Input';

const Aprovações = () => {
  const titulo = 'Aprovações Pendentes';
  const [aprovacoes, setAprovacoes] = useState([]);
  const [selected, setSelected] = useState([]);
  const { user } = useContext(AuthContext);
  const { openModal, closeModal } = useModal();
  const { documento, getLoading, getDocumento } = useContext(DocumentosContext);

  const renderAprovacoes = () => {
    const arr = [];
    documento?.DocumentoItems?.map((di) => {
      di?.DocumentoItemAprovacaos?.map((dia) => {
        const responsaveis = [];
        if (Boolean(dia?.analisa)) {
          responsaveis?.push(dia?.analisa);
        }
        if (Boolean(dia?.aprova)) {
          responsaveis?.push(dia?.aprova);
        }
        arr?.push({
          id: dia?.id,
          motivo: dia?.motivo,
          descricao: di?.descricao,
          aprova_id: dia?.aprova_id,
          analisa_id: dia?.analisa_id,
          documento_item_id: di?.id,
          responsaveis,
        });
      });
    });
    setAprovacoes(arr);
  };

  useEffect(() => {
    renderAprovacoes();
  }, [documento]);

  if (getLoading) {
    return <Loader />;
  }

  const Modal = () => {
    const defaultValues = { acao: '', justificativa: '', aprovado: 'SIM' };
    const { control, handleSubmit, watch, setValue } = useForm({
      defaultValues,
    });
    const { postLoading, postDocumento } = useContext(DocumentosContext);

    const onSubmit = ({ acao, justificativa, aprovado }) => {
      const Itens = documento?.DocumentoItems?.map((di) => ({
        id: di?.id,
        documento_id: di?.documento_id,
        Aprovacoes: di?.DocumentoItemAprovacaos?.map((dia) => {
          if (selected?.includes(dia?.id)) {
            return {
              id: dia?.id,
              documento_item_id: dia?.documento_item_id,
              acao,
              justificativa,
              aprovado,
            };
          }
          return null;
        })?.filter(Boolean),
      }));

      postDocumento({
        data: { Itens },
        cb: () => {
          closeModal();
          getDocumento(documento?.id);
        },
      });
    };

    return (
      <>
        <DialogTitle>Confirmar Aprovação</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Input name="acao" control={control} label="Ação" />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="justificativa"
                control={control}
                label="Justificativa"
                multiline
                rows={5}
              />
            </Grid>
            <Grid item xs={6} display="flex" alignItems="center">
              <Radio
                color="primary"
                size="small"
                checked={watch('aprovado') === 'SIM'}
                onChange={() => setValue('aprovado', 'SIM')}
              />
              <InputLabel>Aprovar</InputLabel>
            </Grid>
            <Grid item xs={6} display="flex" alignItems="center">
              <Radio
                color="primary"
                size="small"
                checked={watch('aprovado') === 'NAO'}
                onChange={() => setValue('aprovado', 'NAO')}
              />
              <InputLabel>Reprovar</InputLabel>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit(onSubmit)} loading={postLoading}>
            Confirmar
          </Button>
          <Button onClick={closeModal} color="secondary">
            Cancelar
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <Container>
      <Header titulo={titulo} />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DataGrid
              rows={aprovacoes}
              columns={[
                {
                  field: 'descricao',
                  headerName: 'Item',
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'responsaveis',
                  headerName: 'Responsáveis',
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'motivo',
                  headerName: 'Motivo',
                  flex: 2,
                  sortable: false,
                },
              ]}
              hideFooter
              autoHeight
              density="compact"
              disableSelectionOnClick
              disableColumnMenu
              showCellRightBorder
              showColumnRightBorder
              checkboxSelection
              localeText={{ noRowsLabel: 'Nenhum Registro.' }}
              selectionModel={selected}
              onSelectionModelChange={(ids) => setSelected(ids)}
              isRowSelectable={({ row }) =>
                row?.analisa_id === user?.id || row?.aprova_id === user?.id
              }
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (Boolean(selected?.length)) {
                  openModal(<Modal />, 80);
                } else {
                  toastWarning('Selecione ao menos um item');
                }
              }}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Aprovações;
