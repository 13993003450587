import moment from 'moment';
import { Box, Chip, Typography } from '@mui/material';

const mock = [
  {
    field: 'situacao',
    renderCell: ({ row }) => <SituacaoCell row={row} />,
  },
  {
    field: 'emitente',
    renderCell: ({ row }) => <EntidadeCell row={row} />,
  },
  {
    field: 'nf',
    renderCell: ({ row }) => <DocumentoCell row={row} />,
  },
  {
    field: 'dhemissao',
    valueGetter: ({ value }) =>
      value && moment(value).format('DD/MM/YYYY [às] HH:mm'),
  },
];

const EntidadeCell = ({ row }) => (
  <Box flex={1}>
    <Typography variant="body2" fontWeight="bold" mb={1}>
      {row?.rsocial}
    </Typography>
    {Boolean(row?.cnpj) && (
      <Box display="flex" alignItems="center">
        <Typography variant="body2" fontWeight="bold" flex={1}>
          CNPJ:{' '}
        </Typography>
        <Typography variant="body2" flex={3}>
          {row?.cnpj
            ?.replace(/\D/g, '')
            ?.replace(/^(\d{2})(\d)/, '$1.$2')
            ?.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
            ?.replace(/\.(\d{3})(\d)/, '.$1/$2')
            ?.replace(/(\d{4})(\d)/, '$1-$2')
            ?.replace(/(-\d{2})\d+?$/, '$1')}
        </Typography>
      </Box>
    )}
    {Boolean(row?.ie) && (
      <Box display="flex" alignItems="center">
        <Typography variant="body2" fontWeight="bold" flex={1}>
          IE:{' '}
        </Typography>
        <Typography variant="body2" flex={3}>
          {row?.ie}
        </Typography>
      </Box>
    )}
  </Box>
);

const DocumentoCell = ({ row }) => (
  <Box flex={1}>
    <Box
      mb={1}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="body2" fontWeight="bold">
        {row?.tpdocto || ''} {row?.numero || ''}
      </Typography>
      {Boolean(row?.status) && (
        <Chip
          label={row?.status}
          color={chipColor[row?.status] || 'primary'}
          size="small"
        />
      )}
    </Box>
    {Boolean(row?.operacao) && (
      <Box display="flex" alignItems="center">
        <Typography variant="body2" fontWeight="bold" flex={1}>
          Operação:{' '}
        </Typography>
        <Typography variant="body2" flex={2}>
          {row?.operacao}
        </Typography>
      </Box>
    )}
    {Boolean(row?.valor) && (
      <Box display="flex" alignItems="center">
        <Typography variant="body2" fontWeight="bold" flex={1}>
          Valor:{' '}
        </Typography>
        <Typography variant="body2" flex={2}>
          {row?.valor?.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}
        </Typography>
      </Box>
    )}
    {Boolean(row?.serie) && (
      <Box display="flex" alignItems="center">
        <Typography variant="body2" fontWeight="bold" flex={1}>
          Série:{' '}
        </Typography>
        <Typography variant="body2" flex={2}>
          {row?.serie}
        </Typography>
      </Box>
    )}
  </Box>
);

const SituacaoCell = ({ row }) => (
  <Box flex={1} alignItems="center" flexDirection="column" display="flex">
    <Chip
      size="small"
      color={situacaoColor[row?.situacao]}
      label={situacaoLabel[row?.situacao] || '-'}
    />
    <Chip
      size="small"
      variant="outlined"
      sx={{ mt: 1 }}
      color={xmlColor[row?.xml]}
      label={xmlLabel[row?.xml] || '-'}
    />
  </Box>
);

const chipColor = {
  AUTORIZADO: 'success',
  DENEGADO: 'error',
  CANCELADO: 'warning',
};
const situacaoColor = {
  210210: 'info',
  210200: 'success',
  210220: 'error',
  210240: 'warning',
  0: 'default',
};
const situacaoLabel = {
  210210: 'Ciência da Operação',
  210200: 'Confirmação da Operação',
  210220: 'Desconhecimento da Operação',
  210240: 'Operação não Realizada',
  0: 'Não Manifestado',
};

const xmlColor = {
  IMPORTAR: 'info',
  IMPORTADO: 'success',
  INDISPONIVEL: 'error',
};

const xmlLabel = {
  IMPORTAR: 'XML Disponível',
  IMPORTADO: 'XML Importado',
  INDISPONIVEL: 'XML Indisponível',
};

export default mock;
