import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useLocation, useNavigate } from 'react-router-dom';
import { RetornaveisContext } from 'contexts/RetornaveisContext';
import ButtonMenu from 'components/ButtonMenu';
import Container from 'components/Container';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Card from 'components/Card';
import moment from 'moment';

const Detalhes = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);
  const { state } = useLocation();
  const { detalhes, getRetornaveisDetalhes, getLoading } =
    useContext(RetornaveisContext);

  useEffect(() => {
    if (state?.cadastro_id && state?.vendedor_id) {
      getRetornaveisDetalhes({
        cadastro_id: state?.cadastro_id,
        vendedor_id: state?.vendedor_id,
      });
    } else {
      navigate(-1);
    }
  }, []);

  const getParams = (especie_id) => {
    const itens = selected
      ?.map((s) => detalhes?.find((f) => f?.id === s))
      ?.filter(Boolean)
      ?.map((i) => ({
        id: i?.id,
        produto_id: i?.componente_id,
        origem_id: i?.documento_item_id,
        preco: i?.preco,
        descricao: i?.produto,
        referencia: i?.referencia,
        quantidade: i?.quantidade,
        quantidade_max: i?.quantidade,
        subtotal: (i?.quantidade || 0) * (i?.preco || 0),
      }));

    return {
      itens,
      cadastro_id: state?.cadastro_id,
      vendedor_id: state?.vendedor_id,
      filial_id: state?.filial_id,
      especie_id,
    };
  };

  const options = [
    {
      name: 'Estoque',
      icon: 'inventory_2',
      action: () =>
        navigate('/app/Retornaveis/Estoque', { state: getParams() }),
    },
    {
      name: 'Perda / Refugo (Fatura)',
      icon: 'block',
      action: () =>
        navigate('/app/Retornaveis/Perda', { state: getParams(25) }),
    },
    {
      name: 'Perda / Refugo (Nota)',
      icon: 'block',
      action: () =>
        navigate('/app/Retornaveis/Perda', { state: getParams(10) }),
    },
    {
      name: 'Venda (Fatura)',
      icon: 'price_check',
      action: () =>
        navigate('/app/Retornaveis/Venda', { state: getParams(25) }),
    },
    {
      name: 'Venda (Nota)',
      icon: 'price_check',
      action: () =>
        navigate('/app/Retornaveis/Venda', { state: getParams(10) }),
    },
  ];

  return (
    <Container>
      <Header
        titulo={`Itens Retornáveis de ${state?.cliente}`}
        subtitulo={`Vendido por ${state?.vendedor}`}
      />
      {getLoading ? (
        <Loader />
      ) : (
        <Card>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ButtonMenu options={options} />
            </Grid>
            <Grid item xs={12}>
              <DataGrid
                rows={detalhes}
                columns={[
                  {
                    field: 'referencia',
                    headerName: 'Referência',
                    flex: 1,
                    sortable: false,
                  },
                  {
                    field: 'produto',
                    headerName: 'Produto',
                    flex: 4,
                    sortable: false,
                  },
                  {
                    field: 'documento',
                    headerName: 'Documento',
                    flex: 2,
                    sortable: false,
                  },
                  {
                    field: 'dtemissao',
                    headerName: 'Data de Emissão',
                    valueGetter: ({ value }) =>
                      value && moment(value).format('DD/MM/YYYY'),
                    flex: 2,
                    sortable: false,
                  },
                  {
                    field: 'modelo',
                    headerName: 'Modelo',
                    flex: 2,
                    sortable: false,
                  },
                  {
                    field: 'quantidade',
                    headerName: 'Quantidade',
                    type: 'number',
                    flex: 2,
                    sortable: false,
                  },
                ]}
                checkboxSelection
                onSelectionModelChange={(arr) => setSelected(arr)}
                hideFooter
                autoHeight
                density="compact"
                disableSelectionOnClick
                disableColumnMenu
                keepNonExistentRowsSelected
                showCellRightBorder
                showColumnRightBorder
                localeText={{ noRowsLabel: 'Nenhum Registro.' }}
              />
            </Grid>
          </Grid>
        </Card>
      )}
    </Container>
  );
};

export default Detalhes;
