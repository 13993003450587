import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { openXML } from 'utils/functions';
import { DropsContext } from 'contexts/DropsContext';
import { ManifestacoesContext } from 'contexts/ManifestacoesContext';
import { GridContext } from 'contexts/GridContext';
import { useModal } from 'components/Modals';
import ResumoModal from 'components/Modals/ResumoModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import TipoConsultaModal from './Modals/TipoConsultaModal';
import TipoImportacaoModal from './Modals/TipoImportacaoModal';
import Filter from './filter';
import mock from './mock';

const Manifestações = () => {
  const rotina = 'Manifestacoes';
  const titulo = 'Manifestações do Destinatário';
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { exportPdf, exportXls } = useContext(GridContext);
  const {
    manifestacoes,
    getLoading,
    getManifestacoes,
    postConsulta,
    postImportar,
    postCiencia,
    postConfirmacao,
    postDesconhecimento,
    postOperacaoNaoRealizada,
  } = useContext(ManifestacoesContext);
  const { control, getValues, reset } = useForm({
    defaultValues: manifestacoes?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    closeModal();
    getManifestacoes({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({ rotina, filter: getValues(), order: manifestacoes?.order }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({ rotina, filter: getValues(), order: manifestacoes?.order }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={manifestacoes?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
    {
      name: 'Carregar Notas',
      icon: 'add',
      action: () =>
        openModal(
          <TipoConsultaModal
            onSubmit={(data) => postConsulta({ data, cb: closeModal })}
          />
        ),
    },
  ];

  const rowOptions = [
    {
      name: 'Importar XML',
      icon: 'file_upload',
      menu: true,
      show: ({ row }) => Boolean(row?.xml === 'IMPORTAR'),
      action: ({ id }) =>
        openModal(
          <TipoImportacaoModal
            id={id}
            onSubmit={(data) => postImportar({ data, cb: loadGrid })}
          />
        ),
    },
    {
      name: 'Visualizar XML',
      icon: 'file_open',
      menu: true,
      show: ({ row }) => Boolean(row?.xml_conteudo),
      action: ({ row }) => openXML(row?.xml_conteudo),
    },
    {
      name: 'Ciência da Operação',
      icon: 'verified',
      menu: true,
      show: ({ row }) => row?.situacao === 0,
      action: ({ id }) => postCiencia({ id, cb: loadGrid }),
    },
    {
      name: 'Confirmação da Operação',
      icon: 'thumb_up',
      menu: true,
      show: ({ row }) => row?.situacao !== 210200,
      action: ({ id }) => postConfirmacao({ id, cb: loadGrid }),
    },
    {
      name: 'Desconhecimento da Operação',
      icon: 'thumb_down',
      show: ({ row }) => row?.situacao !== 210220,
      menu: true,
      action: ({ id }) => postDesconhecimento({ id, cb: loadGrid }),
    },
    {
      name: 'Operação não Realizada',
      icon: 'block',
      menu: true,
      show: ({ row }) => row?.situacao !== 210240,
      action: ({ id }) =>
        postOperacaoNaoRealizada({ id, justificativa: '', cb: loadGrid }),
    },
  ];

  return (
    <Container>
      <Header
        rotina={rotina}
        titulo={titulo}
        colunas={manifestacoes?.colunas}
      />
      <Card>
        <Grid
          grid={manifestacoes}
          mock={mock}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          getRowHeight={() => 'auto'}
          FilterComponent={
            <Filter grid={manifestacoes} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default Manifestações;
