const styles = {
  dropzone: {
    border: '1px solid',
    borderColor: 'primary.main',
    borderRadius: 2,
  },
  dropzoneButton: { mr: 1 },
  dropzoneChip: { m: 0.5 },
};

export default styles;
