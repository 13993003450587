import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  status: ['PENDENTE'],
  situacao: [],
  dtemissao: {
    i: null,
    f: null,
  },
  operacao: [],
  requisitante: [],
  origem: '',
};

export const EstoqueContext = createContext();

export const EstoqueProvider = ({ children }) => {
  const [estoque, setEstoque] = useState({
    data: [],
    colunas: [],
    order: { field: 'dtemissao', sort: 'desc' },
    filter,
    page: 0,
    size: 10,
  });

  const [getLoading, setGetLoading] = useState(false);

  const getEstoque = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: estoque?.filter,
        page: estoque?.page,
        size: estoque?.size,
        order: estoque?.order,
        ...payload,
      });
      setEstoque((prev) => ({ ...prev, ...data, ...payload }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <EstoqueContext.Provider
      value={{
        estoque,
        getLoading,
        getEstoque,
      }}
    >
      {children}
    </EstoqueContext.Provider>
  );
};
