import React, { useContext, useEffect } from 'react';
import {
  Grid,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useForm } from 'react-hook-form';
import { summarizer, getDatePagto, findOnArray } from 'utils/functions';
import { useLocation, useNavigate } from 'react-router-dom';
import { DropsContext } from 'contexts/DropsContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { AuthContext } from 'contexts/AuthContext';
import { useDialog } from 'components/Modals';
import PagamentoModal from './Modals/PagamentoModal';
import TableContainer from 'components/TableContainer';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';
import styles from './styles';
import moment from 'moment';

const Venda = () => {
  const { state } = useLocation();
  const { openDialog, closeDialog } = useDialog();
  const { user } = useContext(AuthContext);
  const defaultValues = {
    observacao: '',
    dadosadc: '',
    itens: [],
    pagamento: [],
    forma_pagto_id: null,
    condicao_pagamento_id: null,
    fixar: false,
  };
  const navigate = useNavigate();
  const { drops } = useContext(DropsContext);
  const { postDocumento, postLoading } = useContext(DocumentosContext);
  const { control, handleSubmit, watch, setValue } = useForm({ defaultValues });

  useEffect(() => {
    if (Boolean(state)) {
      setValue('itens', state?.itens || []);
    } else {
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    handleSubmit(onRecalc)();
  }, [watch('itens')]);

  const onCellEditCommit = (e) => {
    const editedRows = watch('itens')?.map((c) => {
      if (c?.id === e?.id && e?.field === 'quantidade') {
        if (e?.value > c?.quantidade_max) {
          const subtotal = (c?.quantidade_max || 0) * (c?.preco || 0);
          return { ...c, [e?.field]: c?.quantidade_max, subtotal };
        } else {
          const subtotal = (e?.value || 0) * (c?.preco || 0);
          return { ...c, [e?.field]: e?.value, subtotal };
        }
      } else {
        return { ...c };
      }
    });
    setValue('itens', editedRows);
  };

  const onDeleteItem = (params) =>
    setValue(
      'itens',
      watch('itens')?.filter((r) => r?.id !== params?.id)
    );

  const onChangePagto = (values) => {
    const forma_pagto_id = values?.forma_pagto_id;
    const condicao_pagamento_id = values?.condicao_pagamento_id;
    const fixar = values?.fixar;
    if (Boolean(forma_pagto_id) && Boolean(condicao_pagamento_id)) {
      const cond_pagto = findOnArray(
        condicao_pagamento_id,
        drops?.CondicaoPagamento
      );
      const valor_total = summarizer(values?.itens, 'subtotal');

      if (cond_pagto && valor_total > 0) {
        const pagamento = [];
        let valor_lancado = 0;
        for (let parcela = 1; parcela <= cond_pagto?.nvezes; parcela++) {
          const valor = parseFloat(
            (valor_total / cond_pagto?.nvezes).toFixed(2)
          );
          pagamento.push({
            parcela,
            dtvenc: getDatePagto({ ...cond_pagto, parcela, fixar }),
            valor:
              parcela === cond_pagto?.nvezes
                ? valor_total - valor_lancado
                : valor,
            forma_pagto_id,
          });
          valor_lancado += valor;
        }
        setValue('pagamento', pagamento);
      } else {
        setValue('pagamento', []);
      }
    }
  };

  const onEditPagto = ({ values, index }) => {
    const pagamento = watch('pagamento');
    setValue(
      'pagamento',
      pagamento?.map((p, i) =>
        index === i
          ? { ...p, ...values, parcela: i + 1 }
          : { ...p, parcela: i + 1 }
      )
    );
    closeDialog();
  };

  const onDeletePagto = (index) => {
    setValue(
      'pagamento',
      watch('pagamento')?.filter((_, i) => i !== index)
    );
  };

  const onRecalc = (values) => {
    const valor_total = summarizer(values?.itens, 'subtotal');
    const parcelas = values?.pagamento?.length;
    if (Boolean(parcelas) && valor_total > 0) {
      let valor_lancado = 0;
      const pagamento = values?.pagamento.map((p, i) => {
        let valor =
          parcelas === i + 1
            ? valor_total - valor_lancado
            : parseFloat((valor_total / parcelas).toFixed(2));
        valor_lancado += valor;
        return { ...p, valor };
      });
      setValue('pagamento', pagamento);
    } else {
      setValue('pagamento', []);
    }
  };

  const onSubmit = (values) => {
    const data = {
      Documento: {
        especie_id: state?.especie_id,
        natureza_operacao_id: 38,
        users_id: user?.id,
        filial_id: state?.filial_id,
        cadastro_id: state?.cadastro_id,
        dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
        observacao: values?.observacao,
        dadosadc: values?.dadosadc,
      },
      Saida: {
        vendedor_id: state?.vendedor_id,
        condicao_pagamento_id: values?.condicao_pagamento_id,
        dtsaida: moment().format('YYYY-MM-DD HH:mm:ss'),
      },
      Itens: values?.itens?.map((i, index) => ({
        sequencia: index + 1,
        produto_id: i?.produto_id,
        descricao: i?.descricao,
        referencia: i?.referencia,
        quantidade: i?.quantidade,
        preco: i?.preco,
        Origens: [{ documento_item_org_id: i?.origem_id }],
      })),
      Financeiros: values?.pagamento,
    };
    postDocumento({ data, cb: () => navigate(-1) });
  };

  return (
    <Container>
      <Header titulo="Venda" />
      <Card title="Informações Adicionais">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Input
              name="observacao"
              control={control}
              label="Observação"
              multiline
              rows={5}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="dadosadc"
              control={control}
              label="Dados Adicionais"
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </Card>
      <Card title="Itens" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DataGrid
                    rows={watch('itens')}
                    columns={[
                      {
                        field: 'referencia',
                        headerName: 'Referência',
                        flex: 1,
                        sortable: false,
                      },
                      {
                        field: 'descricao',
                        headerName: 'Produto',
                        flex: 4,
                        sortable: false,
                      },
                      {
                        field: 'quantidade',
                        headerName: 'Quantidade',
                        type: 'number',
                        flex: 1,
                        editable: true,
                        sortable: false,
                      },
                      {
                        field: 'preco',
                        headerName: 'Preço Unitário',
                        type: 'number',
                        valueGetter: ({ value }) =>
                          value &&
                          value?.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          }),
                        flex: 1,
                        sortable: false,
                      },
                      {
                        field: 'subtotal',
                        headerName: 'Subtotal',
                        type: 'number',
                        valueGetter: ({ value }) =>
                          value &&
                          value?.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          }),
                        flex: 1,
                        sortable: false,
                      },
                      {
                        field: 'actions',
                        headerName: 'Ações',
                        type: 'actions',
                        flex: 1,
                        getActions: (params) => [
                          <GridActionsCellItem
                            icon={<Icon>delete</Icon>}
                            label="Excluir"
                            onClick={() => onDeleteItem(params)}
                          />,
                        ],
                      },
                    ]}
                    hideFooter
                    autoHeight
                    density="compact"
                    disableSelectionOnClick
                    disableColumnMenu
                    showCellRightBorder
                    showColumnRightBorder
                    localeText={{ noRowsLabel: 'Nenhum Registro.' }}
                    onCellEditCommit={onCellEditCommit}
                  />
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Typography variant="h6" color="primary">
                    Total:{' '}
                    {summarizer(watch('itens'), 'subtotal')?.toLocaleString(
                      'pt-br',
                      {
                        style: 'currency',
                        currency: 'BRL',
                      }
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Card>
      <Card title="Pagamento" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} display="flex">
            <Card style={styles?.semiCard}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="forma_pagto_id"
                    control={control}
                    label="Forma de Pagamento"
                    options={drops?.FormaDePagamento?.filter(
                      (f) => f?.modulo !== 'COMPRAS' && f?.modulo !== 'CHEQUES'
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="condicao_pagamento_id"
                    control={control}
                    label="Condição de Pagamento"
                    options={drops?.CondicaoPagamento?.filter(
                      (c) => c?.modulo !== 'COMPRAS'
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox name="fixar" control={control} label="Fixar Dia" />
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit(onChangePagto)}
                  >
                    CALCULAR
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} display="flex">
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Parcela</TableCell>
                          <TableCell>Data</TableCell>
                          <TableCell>Valor</TableCell>
                          <TableCell>Forma de Pagamento</TableCell>
                          <TableCell align="center">Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {watch('pagamento')?.map((item, index) => (
                          <TableRow key={index?.toString()}>
                            <TableCell>{item?.parcela}</TableCell>
                            <TableCell>
                              {Boolean(item?.dtvenc) &&
                                moment(item?.dtvenc).isValid() &&
                                moment(item?.dtvenc).format('DD/MM/YYYY')}
                            </TableCell>
                            <TableCell>
                              {item?.valor?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </TableCell>
                            <TableCell>
                              {findOnArray(
                                item?.forma_pagto_id,
                                drops?.FormaDePagamento,
                                'label'
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  openDialog(
                                    <PagamentoModal
                                      item={item}
                                      onSubmit={(values) =>
                                        onEditPagto({ values, index })
                                      }
                                    />,
                                    'Editar Parcela'
                                  )
                                }
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => onDeletePagto(index)}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h6" color="primary">
              Total:{' '}
              {summarizer(watch('pagamento'), 'valor')?.toLocaleString(
                'pt-br',
                { style: 'currency', currency: 'BRL' }
              )}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Grid item xs={12} textAlign="center" sx={styles?.button}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
      </Grid>
    </Container>
  );
};

export default Venda;
