import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useDialog } from 'components/Modals';
import { DocumentosContext } from 'contexts/DocumentosContext';
import Card from 'components/Card';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import TableContainer from 'components/TableContainer';
import styles from '../styles';

const Variações = ({
  drops,
  onSubmit,
  onDelete,
  disableActions,
  documentoItem = {},
}) => {
  const { openDialog } = useDialog();
  const rotina = 'DocumentoItemVariacao';

  const onConfirm = (values) => {
    const data = {
      Itens: [
        {
          id: documentoItem?.id,
          documento_id: documentoItem?.documento_id,
          Variacoes: [values],
        },
      ],
    };

    onSubmit(data);
  };

  const Modal = ({ item }) => {
    const defaultValues = {
      id: item?.id,
      documento_item_id: item?.documento_item_id,
      variacao_id: item?.variacao_id || null,
      quantidade: item?.quantidade || '',
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(DocumentosContext);

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Dropdown
            name="variacao_id"
            control={control}
            label="Variação"
            options={drops?.ProdutoVariacao?.filter(
              (f) => f?.produto_id === documentoItem?.produto_id
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="quantidade" control={control} label="Quantidade" />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onConfirm)}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Card title="Variações" style={styles?.card}>
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => openDialog(<Modal />, 'Adicionar Variação')}
            >
              Adicionar
            </Button>
          </Grid>
        )}
        {!!documentoItem?.DocumentoItemVariacaos?.length && (
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Variação</TableCell>
                    <TableCell>Quantidade</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documentoItem?.DocumentoItemVariacaos?.map((item) => (
                    <TableRow key={item?.id?.toString()}>
                      <TableCell>{item?.variacao}</TableCell>
                      <TableCell>{item?.quantidade}</TableCell>
                      <TableCell align="center">
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() =>
                              openDialog(
                                <Modal item={item} />,
                                'Editar Variação'
                              )
                            }
                          >
                            <Edit />
                          </IconButton>
                        )}
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() => onDelete({ rotina, id: item?.id })}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Variações;
