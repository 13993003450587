import { createContext, useState } from 'react';
import api from 'services/api';

export const RetornaveisContext = createContext();

export const RetornaveisProvider = ({ children }) => {
  const [retornaveis, setRetornaveis] = useState([]);
  const [detalhes, setDetalhes] = useState([]);
  const [getLoading, setGetLoading] = useState(false);

  const getRetornaveis = async (params) => {
    try {
      setGetLoading(true);
      const { data } = await api.get('/Suprimentos/Retornaveis', { params });
      setRetornaveis(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getRetornaveisDetalhes = async (params) => {
    try {
      setGetLoading(true);
      const { data } = await api.get('/Suprimentos/Retornaveis/Detalhes', {
        params,
      });
      setDetalhes(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <RetornaveisContext.Provider
      value={{
        retornaveis,
        getLoading,
        getRetornaveis,
        detalhes,
        getRetornaveisDetalhes,
      }}
    >
      {children}
    </RetornaveisContext.Provider>
  );
};
