import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  Box,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Check, Close, Edit } from '@mui/icons-material';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { ProdutosContext } from 'contexts/ProdutosContext';
import { useModal } from 'components/Modals';
import Container from 'components/Container';
import InputMask from 'components/InputMask';
import Header from 'components/Header';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Card from 'components/Card';
import moment from 'moment';

const FormaçãoDePreço = () => {
  const titulo = 'Formação De Preço';
  const navigate = useNavigate();
  const { state } = useLocation();
  const [rows, setRows] = useState([]);
  const { openModal, closeModal } = useModal();
  const { postLoading, postProdutos } = useContext(ProdutosContext);
  const { getFormacaoPreco, getLoading } = useContext(DocumentosContext);
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: { markup: '' },
  });

  const loadData = () => getFormacaoPreco({ id: state?.id, cb: setRows });

  useEffect(() => {
    if (Boolean(state?.id)) {
      loadData();
    } else {
      navigate(-1);
    }
  }, []);

  const onRefresh = ({ markup }) => {
    if (!isNaN(Number(markup))) {
      const data = rows?.map((r) => ({
        Preco: {
          produto_id: r?.produto_id,
          documento_item_id: r?.id,
          preco: r?.custo * (Number(markup) / 100 + 1),
        },
      }));

      const cb = () => {
        loadData();
        closeModal();
        setValue('markup', '');
      };

      postProdutos({ data, cb });
    }
  };

  const onSubmit = (values) => {
    const data = [
      {
        Preco: {
          ...values,
          ...(Boolean(values?.code === 'markup') && {
            preco: values?.custo * ((values?.markup || 0) / 100 + 1),
          }),
        },
      },
    ];

    const cb = () => {
      loadData();
      closeModal();
    };

    postProdutos({ data, cb });
  };

  const ProdutoCell = ({ row }) => {
    const lines = [
      { label: 'Referência', value: row?.referencia },
      { label: 'Referência do Fornecedor', value: row?.referencia_fornecedor },
      { label: 'Vendido por', value: row?.lojas },
    ];
    return (
      <Box display="flex" alignItems="center" flex={1}>
        <Box flex={1}>
          <Typography variant="body1" fontWeight="bold" mb={1}>
            {row?.descricao || ''}
          </Typography>
          {lines?.map(
            ({ label, value }) =>
              Boolean(value) && (
                <Box display="flex" alignItems="center" flex={1}>
                  <Typography variant="caption" fontWeight="bold" flex={3}>
                    {label}:{' '}
                  </Typography>
                  <Typography variant="caption" flex={2}>
                    {value}
                  </Typography>
                </Box>
              )
          )}
        </Box>
        {Boolean(row?.produto_id) && (
          <Chip
            icon={row?.ativo === 'SIM' ? <Check /> : <Close />}
            label={row?.ativo === 'SIM' ? 'Ativo' : 'Inativo'}
            color={row?.ativo === 'SIM' ? 'success' : 'error'}
            size="small"
            variant="outlined"
          />
        )}
      </Box>
    );
  };

  const VigenteCell = ({ row }) => {
    const markup = (row?.preco / (row?.custo || 1) - 1) * 100;
    const preco_vigente = row?.custo * (markup / 100 + 1);
    const markup_vigente =
      (preco_vigente / (row?.custo_vigente || 1) - 1) * 100;

    const lines = [
      {
        label: 'Preço de Venda',
        value: preco_vigente?.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
      },
      {
        label: 'Custo de Compra',
        value: row?.custo_vigente?.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
      },
      {
        label: 'Markup',
        value:
          markup_vigente?.toLocaleString('pt-br', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }) + '%',
      },
    ];

    return (
      <Box flex={1}>
        {lines?.map(({ label, value, edit }) => (
          <Box display="flex" alignItems="center" flex={1} my={1}>
            <Typography variant="caption" fontWeight="bold" flex={1}>
              {label}:{' '}
            </Typography>
            <Typography variant="caption" flex={1}>
              {value}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  const NotaCell = ({ row }) => {
    const markup = (row?.preco / (row?.custo || 1) - 1) * 100;

    const lines = [
      {
        label: 'Preço de Venda',
        edit: 'preco',
        value: row?.preco?.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
      },
      {
        label: 'Custo de Compra',
        value: row?.custo?.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
      },
      {
        label: 'Markup',
        edit: 'markup',
        value:
          markup?.toLocaleString('pt-br', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }) + '%',
      },
    ];

    return (
      <Box flex={1}>
        {lines?.map(({ label, value, edit }) => (
          <Box display="flex" alignItems="center" flex={1} my={1}>
            <Typography variant="caption" fontWeight="bold" flex={1}>
              {label}:{' '}
            </Typography>
            <Box display="flex" alignItems="center" flex={1}>
              <Typography variant="caption" flex={1}>
                {value}
              </Typography>
              {Boolean(edit) && (
                <Edit
                  sx={{ fontSize: 14, cursor: 'pointer' }}
                  color="primary"
                  onClick={() => openModal(<Modal row={row} code={edit} />)}
                />
              )}
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  const ComprasCell = ({ value }) => {
    const labelStyle = {
      variant: 'caption',
      fontSize: 12,
      fontWeight: 'bold',
      lineHeight: 1,
    };
    const valueStyle = { variant: 'caption', fontSize: 12, lineHeight: 1 };
    const boxStyle = {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      mt: 1,
    };

    return (
      <Grid container spacing={2}>
        {(value || [])?.map((m, i) => (
          <Grid item xs={4} my={1}>
            <Card>
              <Box {...boxStyle} mt={0}>
                <Typography {...labelStyle}>Fornecedor</Typography>
                <Typography {...valueStyle}>{m?.nome || '-'}</Typography>
              </Box>
              <Box {...boxStyle}>
                <Typography {...labelStyle}>Data da Compra</Typography>
                <Typography {...valueStyle}>
                  {moment(m?.dtemissao).format('DD/MM/YYYY')}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Box {...boxStyle}>
                  <Typography {...labelStyle}>Preço</Typography>
                  <Typography {...valueStyle}>
                    {(m?.preco || 0)?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </Typography>
                </Box>
                <Box {...boxStyle}>
                  <Typography {...labelStyle}>Custo</Typography>
                  <Typography {...valueStyle}>
                    {(m?.custo || 0)?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  const ImpostoCell = ({ row }) => (
    <Box display="flex" alignItems="center" flex={1}>
      <Typography variant="body2" flex={1}>
        {(row?.difal || 0)?.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })}{' '}
        ({row?.icmsst_pc || 0}%)
      </Typography>
      <Edit
        sx={{ fontSize: 14, cursor: 'pointer' }}
        color="primary"
        onClick={() => openModal(<Modal row={row} code="icmsst_pc" />)}
      />
    </Box>
  );

  const Modal = ({ row, code }) => {
    const { postLoading } = useContext(ProdutosContext);
    const { control, handleSubmit } = useForm({
      defaultValues: {
        code,
        custo: row?.custo,
        documento_item_id: row?.id,
        produto_id: row?.produto_id,
        icmsst_pc: row?.icmsst_pc || '',
        preco: row?.preco || '',
        markup: '',
      },
    });

    const label = {
      icmsst_pc: '% do ST / DIFAL',
      markup: 'Markup',
      preco: 'Preço de Venda',
    };
    const icon = {
      icmsst_pc: '%',
      markup: '%',
      preco: 'R$',
    };

    return (
      <>
        <DialogTitle>Alteção de Dados</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <InputMask
                name={code}
                control={control}
                label={label[code]}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{icon[code]}</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            loading={postLoading}
          >
            Salvar
          </Button>
          <Button variant="contained" color="secondary" onClick={closeModal}>
            Voltar
          </Button>
        </DialogActions>
      </>
    );
  };

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo={titulo} />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} display="flex" alignItems="center">
            <InputMask name="markup" control={control} label="Markup" />
            <Button
              variant="outlined"
              color="primary"
              sx={{ ml: 1 }}
              onClick={handleSubmit(onRefresh)}
              loading={postLoading}
            >
              Atualizar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              rows={rows}
              columns={[
                {
                  field: 'produto',
                  headerName: 'Produto',
                  width: 400,
                  sortable: false,
                  renderCell: (props) => <ProdutoCell {...props} />,
                },
                {
                  field: 'quantidade',
                  headerName: 'Quantidade',
                  type: 'number',
                  width: 100,
                  sortable: false,
                  valueGetter: ({ row }) =>
                    `${row?.quantidade} ${row?.unidade_medida}`,
                },
                {
                  field: 'vigente',
                  headerName: 'Valores Vigentes',
                  width: 300,
                  sortable: false,
                  renderCell: (props) => <VigenteCell {...props} />,
                },
                {
                  field: 'nf',
                  headerName: 'Valores da Nota',
                  width: 300,
                  sortable: false,
                  renderCell: (props) => <NotaCell {...props} />,
                },
                {
                  field: 'difal',
                  headerName: 'ST / DIFAL',
                  type: 'number',
                  width: 140,
                  sortable: false,
                  renderCell: (props) => <ImpostoCell {...props} />,
                },
                {
                  field: 'compras',
                  headerName: 'Últimas Compras',
                  width: 600,
                  sortable: false,
                  renderCell: (props) => <ComprasCell {...props} />,
                },
              ]}
              hideFooter
              autoHeight
              density="compact"
              disableSelectionOnClick
              disableColumnMenu
              showCellRightBorder
              showColumnRightBorder
              getRowHeight={() => 'auto'}
              localeText={{ noRowsLabel: 'Nenhum Registro.' }}
            />
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default FormaçãoDePreço;
