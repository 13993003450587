import { createContext, useState } from 'react';
import api from 'services/api';

export const ProdutosContext = createContext();

export const ProdutosProvider = ({ children }) => {
  const [postLoading, setPostLoading] = useState(false);

  const postProdutos = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Cadastros/Produto/Produtos', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postEtiquetas = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Cadastros/Produto/Etiquetas', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  return (
    <ProdutosContext.Provider
      value={{ postProdutos, postEtiquetas, postLoading }}
    >
      {children}
    </ProdutosContext.Provider>
  );
};
