import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Grid, Icon, Switch, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { AuthContext } from 'contexts/AuthContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { summarizer } from 'utils/functions';
import Container from 'components/Container';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Button from 'components/Button';
import Card from 'components/Card';
import Input from 'components/Input';
import styles from './styles';
import moment from 'moment';

const Devolução = () => {
  const titulo = 'Devolução';
  const navigate = useNavigate();
  const defaultValues = { natureza_operacao_id: 7, observacao: '' };
  const [itens, setItens] = useState([]);
  const { user } = useContext(AuthContext);
  const { documento, getLoading, postLoading, postDocumento } =
    useContext(DocumentosContext);
  const { control, handleSubmit, watch, setValue } = useForm({ defaultValues });

  const renderItens = () => {
    const arr = documento?.DocumentoItems?.map((di) => {
      const quantidade_gerada = summarizer(
        di?.Vinculos?.filter(
          (d) =>
            (d?.especie_id === 10 || d?.especie_id === 25) &&
            (d?.natureza_operacao_id === 7 || d?.natureza_operacao_id === 8)
        ),
        'quantidade'
      );
      const quantidade = di?.quantidade - quantidade_gerada;
      const subtotal =
        (quantidade || 0) * (di?.preco || 0) + (di?.desconto_vlr || 0);
      return {
        ...di,
        subtotal,
        quantidade,
        quantidade_max: quantidade,
      };
    })?.filter((f) => Boolean(f?.quantidade));
    setItens(arr);
  };

  const onDeleteItem = (row) =>
    setItens((prev) => prev?.filter((p) => p?.id !== row?.id));

  const onCellEditCommit = (e) => {
    const editedRows = itens?.map((c) => {
      if (c?.id === e?.id) {
        if (e?.field === 'quantidade' && e?.value > c?.quantidade_max) {
          const subtotal =
            (c?.quantidade_max || 0) * (c?.preco || 0) + (c?.desconto_vlr || 0);
          return { ...c, [e?.field]: c?.quantidade_max, subtotal };
        } else {
          const subtotal =
            (e?.value || 0) * (c?.preco || 0) + (c?.desconto_vlr || 0);
          return { ...c, [e?.field]: e?.value, subtotal };
        }
      } else {
        return { ...c };
      }
    });
    setItens(editedRows);
  };

  const onSubmit = (values) => {
    const data = {
      Documento: {
        especie_id: documento?.especie_id === 5 ? 10 : 25,
        natureza_operacao_id: values?.natureza_operacao_id,
        filial_id: documento?.filial_id,
        cadastro_id: documento?.cadastro_id,
        dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
        celocal_id: documento?.celocal_id,
        contribuinte_id: documento?.contribuinte_id,
        usofinal: documento?.usofinal,
        users_id: user?.id,
        observacao: values?.observacao,
      },
      Saida: {
        condicao_pagamento_id: 1,
        vendedor_id: user?.entidade_id,
      },
      Financeiros: [
        {
          forma_pagto_id: 1,
          parcela: 1,
          dtvenc: moment().format('YYYY-MM-DD'),
          valor: summarizer(itens, 'subtotal') || 0,
        },
      ],
      Itens: itens?.map((r, i) => ({
        sequencia: i + 1,
        cfiscal_id: r?.cfiscal_id,
        produto_id: r?.produto_id,
        descricao: r?.descricao,
        quantidade: r?.quantidade,
        unidade_medida_id: r?.unidade_medida_id,
        precobase_id: r?.precobase_id,
        preco: r?.preco,
        comissao_id: r?.comissao_id,
        comissao_pc: r?.comissao_pc,
        desconto_vlr: r?.desconto_vlr,
        desconto1_pc: r?.desconto1_pc,
        desconto2_pc: r?.desconto2_pc,
        desconto3_pc: r?.desconto3_pc,
        desconto4_pc: r?.desconto4_pc,
        desconto5_pc: r?.desconto5_pc,
        frete: r?.frete,
        seguro: r?.seguro,
        despac: r?.despac,
        cstfin: r?.cstfin,
        eventuais: r?.eventuais,
        outros: r?.outros,
        peso: r?.peso,
        przent: r?.przent,
        infadc: r?.infadc,
        referencia: r?.referencia,
        calcimp: 'SIM',
        reserva: r?.reserva,
        custo_id: r?.custo_id,
        ccusto_id: r?.ccusto_id,
        aplicacao_id: r?.aplicacao_id,
        planoconta_id: r?.planoconta_id,
        frota_id: r?.frota_id,
        observacao_id: r?.observacao_id,
        observacao: r?.observacao,
        dhsaldo: r?.dhsaldo,
        pedcomp: r?.pedcomp,
        itempedcomp: r?.itempedcomp,
        Vinculos: [{ documento_item_id_vinculo: r?.id }],
        Origens: [{ documento_item_org_id: r?.id }],
      })),
    };
    postDocumento({
      data,
      cb: () => navigate(-1),
    });
  };

  useEffect(() => {
    renderItens();
  }, [documento]);

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo={titulo} />
      <Card title="Itens">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DataGrid
              rows={itens}
              columns={[
                {
                  field: 'referencia',
                  headerName: 'Referência',
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'descricao',
                  headerName: 'Descrição',
                  flex: 2,
                  sortable: false,
                },
                {
                  field: 'quantidade',
                  headerName: 'Quantidade',
                  flex: 1,
                  type: 'number',
                  sortable: false,
                  editable: true,
                },
                {
                  field: 'desconto_vlr',
                  headerName: 'Desconto',
                  type: 'number',
                  valueGetter: ({ value }) =>
                    value &&
                    value?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }),
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'preco',
                  headerName: 'Preço Unitário',
                  type: 'number',
                  valueGetter: ({ value }) =>
                    value &&
                    value?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }),
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'subtotal',
                  headerName: 'Subtotal',
                  type: 'number',
                  valueGetter: ({ value }) =>
                    value &&
                    value?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }),
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'actions',
                  headerName: 'Ações',
                  type: 'actions',
                  flex: 1,
                  getActions: ({ row }) => [
                    <GridActionsCellItem
                      icon={<Icon>delete</Icon>}
                      label="Excluir"
                      onClick={() => onDeleteItem(row)}
                    />,
                  ],
                },
              ]}
              hideFooter
              autoHeight
              density="compact"
              disableSelectionOnClick
              disableColumnMenu
              showCellRightBorder
              showColumnRightBorder
              onCellEditCommit={onCellEditCommit}
              localeText={{ noRowsLabel: 'Nenhum Registro.' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" align="center">
              Total:{' '}
              {(summarizer(itens, 'subtotal') || 0)?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Card title="Informações Adicionais" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} display="flex" alignItems="center">
            <Switch
              checked={Boolean(watch('natureza_operacao_id') === 8)}
              onChange={(_, v) => setValue('natureza_operacao_id', v ? 8 : 7)}
            />
            <Typography>Movimentar estoque</Typography>
          </Grid>
          <Grid item xs={12}>
            <Input
              name="observacao"
              control={control}
              label="Observação"
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </Card>
      <Button
        sx={styles?.button}
        variant="contained"
        color="primary"
        onClick={handleSubmit(onSubmit)}
        loading={postLoading}
      >
        Salvar
      </Button>
    </Container>
  );
};

export default Devolução;
