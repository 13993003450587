const styles = {
  card: {
    marginTop: '1em',
  },
  semiCard: {
    width: '100%',
  },
  button: {
    margin: '1em 0',
  },
  image: {
    objectFit: 'contain',
    maxHeight: '150px',
    overflow: 'hidden',
    width: '100%',
  },
};

export default styles;
