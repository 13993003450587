import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Description, ShoppingCart } from '@mui/icons-material';
import { Box, Grid, Pagination, Typography } from '@mui/material';
import {
  DataGrid,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { AuthContext } from 'contexts/AuthContext';
import { DropsContext } from 'contexts/DropsContext';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { RequisicoesContext } from 'contexts/RequisicoesContext';
import Card from 'components/Card';
import Input from 'components/Input';
import Header from 'components/Header';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Container from 'components/Container';
import InputMask from 'components/InputMask';
import VirtualDrop from 'components/VirtualDrop';
import localeText from 'utils/localeText';
import moment from 'moment';

const Necessidade = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { postDocumento, postLoading } = useContext(DocumentosContext);
  const { getNecessidade, getLoading } = useContext(RequisicoesContext);
  const {
    getRelatorios,
    getLoading: rltLoading,
    relatorios,
  } = useContext(RelatoriosContext);
  const { control, setValue, watch, handleSubmit } = useForm();
  const [url, setUrl] = useState(null);
  const [rows, setRows] = useState([]);
  const [filtros, setFiltros] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    getRelatorios();
  }, []);

  useEffect(() => {
    const filtros =
      relatorios?.find((f) => f?.nome === 'Reposição de estoque')?.fields || [];
    setFiltros(filtros);
    filtros.map((item) => {
      switch (item?.type) {
        case 'date':
          setValue(item?.field, null);
          break;
        case 'drop':
          setValue(item?.field, null);
          break;
        default:
          setValue(item?.field, '');
          break;
      }
    });
  }, [relatorios]);

  const onFilter = (params) => {
    setSelected([]);
    setUrl(null);
    setRows([]);

    getNecessidade({
      params,
      cb: (data) => {
        const arr = data?.data || [];
        setUrl(data?.url || null);
        setRows(
          arr?.map((m, i) => ({ ...m, id: i + 1, input: m?.necessidade }))
        );
      },
    });
  };

  const onCellEditCommit = ({ id, field, value }) => {
    setRows((prev) =>
      prev?.map((p) => (p?.id === id ? { ...p, [field]: value } : p))
    );
  };

  const onSubmit = () => {
    const dtemissao = moment().format('YYYY-MM-DD HH:mm:ss');
    const data = {
      Documento: {
        especie_id: 2,
        natureza_operacao_id: 1,
        users_id: user?.id,
        filial_id: user?.filial_id,
        dtemissao,
      },
      Entrada: { dtentrada: dtemissao },
      RequisicaoCompra: { users_id: user?.id },
      Itens: selected?.map((i, index) => {
        const item = rows?.find((f) => f?.id === i);
        return {
          sequencia: index + 1,
          produto_id: item?.produto_id,
          descricao: item?.descricao,
          quantidade: Number(item?.input),
          referencia: item?.referencia,
        };
      }),
    };

    postDocumento({ data, cb: () => navigate(-1) });
  };

  const ProdutoCell = ({ row }) => (
    <Box flex={1}>
      <Typography variant="body1" fontWeight="bold" mb={1}>
        {row?.descricao || ''}
      </Typography>
      <Box display="flex" alignItems="center" flex={1}>
        <Typography variant="caption" fontWeight="bold" flex={1}>
          Referência:
        </Typography>
        <Typography variant="caption" flex={2}>
          {row?.referencia || '-'}
        </Typography>
      </Box>
    </Box>
  );

  const NecessidadeCell = ({ row }) => (
    <Box flex={1}>
      <Typography variant="body2" fontWeight="bold">
        {row?.necessidade || '0'} {row?.unidade || ''}
      </Typography>
    </Box>
  );

  const InputCell = ({ row }) => (
    <Typography variant="body2">
      {row?.input || '0'} {row?.unidade || ''}
    </Typography>
  );

  const CustomPagination = () => {
    const gridRef = useGridApiContext();
    const count = useGridSelector(gridRef, gridPageCountSelector);
    return (
      <Pagination
        color="primary"
        count={count}
        onChange={(_, value) => gridRef?.current?.setPage(value - 1)}
      />
    );
  };

  if (rltLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo="Necessidade de Compra" />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Card title="Filtros">
            <Grid container spacing={2}>
              {Object?.keys(watch())?.map((m, i) => {
                const input = filtros?.find((f) => f?.field === m);
                const field = input?.field || '';
                const label = input?.label || '';
                const options = input?.optionsEval
                  ? eval(input?.optionsEval)
                  : typeof input?.options === 'string'
                  ? drops[input?.options]
                  : input?.options;

                const setRender = () => {
                  switch (input?.type) {
                    case 'date':
                      return (
                        <InputMask
                          name={field}
                          control={control}
                          label={label}
                          type="date"
                        />
                      );
                    case 'number':
                      return (
                        <InputMask
                          name={field}
                          control={control}
                          label={label}
                        />
                      );
                    case 'string':
                      return (
                        <Input name={field} control={control} label={label} />
                      );
                    case 'drop':
                      return (
                        <VirtualDrop
                          name={field}
                          control={control}
                          label={label}
                          options={options}
                        />
                      );
                    default:
                      return null;
                  }
                };
                return (
                  <Grid item xs={12} key={i?.toString()}>
                    {setRender()}
                  </Grid>
                );
              })}
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onFilter)}
                  loading={getLoading}
                >
                  Filtrar
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {getLoading && <Loader />}
        {!getLoading && (
          <Grid item xs={12} sm={8}>
            <Card title="Produtos">
              {Boolean(url) && (
                <Box sx={{ mb: 2, textAlign: 'center' }}>
                  <Button
                    variant="outlined"
                    endIcon={<Description />}
                    onClick={() => window.open(url)}
                  >
                    Ver relatório
                  </Button>
                </Box>
              )}
              <DataGrid
                rows={rows}
                columns={[
                  {
                    field: 'produto',
                    headerName: 'Produto',
                    flex: 1,
                    sortable: false,
                    renderCell: (props) => <ProdutoCell {...props} />,
                  },
                  {
                    field: 'necessidade',
                    headerName: 'Necessidade',
                    width: 200,
                    sortable: false,
                    renderCell: (props) => <NecessidadeCell {...props} />,
                  },
                  {
                    field: 'input',
                    headerName: 'Reposição',
                    width: 200,
                    sortable: false,
                    editable: true,
                    renderCell: (props) => <InputCell {...props} />,
                  },
                ]}
                autoHeight
                pagination
                pageSize={10}
                density="compact"
                checkboxSelection
                disableSelectionOnClick
                disableColumnMenu
                keepNonExistentRowsSelected
                showCellRightBorder
                showColumnRightBorder
                getRowHeight={() => 'auto'}
                localeText={localeText}
                selectionModel={selected}
                onSelectionModelChange={setSelected}
                onCellEditCommit={onCellEditCommit}
                components={{
                  Pagination: CustomPagination,
                }}
              />
              <Box sx={{ mt: 2, textAlign: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onSubmit}
                  loading={postLoading}
                  startIcon={<ShoppingCart />}
                >
                  Requisitar Compra
                </Button>
              </Box>
            </Card>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default Necessidade;
