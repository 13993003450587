import React, { useContext, useState } from 'react';
import { Box, Chip, Grid, Switch, Typography } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { NotasContext } from 'contexts/NotasContext';
import { DropsContext } from 'contexts/DropsContext';
import { useDialog } from 'components/Modals';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import styles from './styles';
import Card from 'components/Card';

const ImportaXMLModal = ({ callback }) => {
  const defaultValues = {
    integracao: null,
    produto: 'NAO',
    transportador: 'NAO',
  };
  const [files, setFiles] = useState([]);
  const { closeDialog } = useDialog();
  const { drops } = useContext(DropsContext);
  const { importXML, postLoading } = useContext(NotasContext);
  const { control, handleSubmit, setValue, watch } = useForm({ defaultValues });

  const onSubmit = (values) => {
    const formData = new FormData();
    files?.map((f) => formData.append('midias', f));
    formData.append('integracao', values?.integracao);
    formData.append('produto', values?.produto);
    formData.append('transportador', values?.transportador);

    importXML({
      formData,
      cb: () => {
        if (callback) {
          callback();
        }
        closeDialog();
      },
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Dropdown
          name="integracao"
          control={control}
          label="Integração"
          options={drops?.IntegraLoja}
        />
      </Grid>
      <Grid item xs={12} md={6} display="flex" alignItems="center">
        <Switch
          checked={Boolean(watch('produto') === 'SIM')}
          onChange={(_, v) => setValue('produto', v ? 'SIM' : 'NAO')}
        />
        <Typography>Cadastramento automático de produtos</Typography>
      </Grid>
      <Grid item xs={12} md={6} display="flex" alignItems="center">
        <Switch
          checked={Boolean(watch('transportador') === 'SIM')}
          onChange={(_, v) => setValue('transportador', v ? 'SIM' : 'NAO')}
        />
        <Typography>Cadastramento automático do transportador</Typography>
      </Grid>
      <Grid item xs={12}>
        <Card title="Arquivos">
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="end"
              justifyContent="space-between"
            >
              <Box>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => document.getElementById('upload-xml')?.click()}
                  startIcon={<Add />}
                  sx={styles?.dropzoneButton}
                >
                  Adicionar
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setFiles([])}
                  startIcon={<Delete />}
                  sx={styles?.dropzoneButton}
                >
                  Limpar
                </Button>
              </Box>
              <Typography variant="body2">
                {files?.length} arquivos selecionados
              </Typography>
            </Grid>
            {Boolean(files?.length) && (
              <Grid item xs={12}>
                <Box sx={styles?.dropzone}>
                  {files?.map((f, i) => (
                    <Chip
                      key={i?.toString()}
                      label={f?.name}
                      color="primary"
                      variant="filled"
                      sx={styles?.dropzoneChip}
                      onDelete={() =>
                        setFiles((prev) => prev?.filter((_, ix) => ix !== i))
                      }
                    />
                  ))}
                </Box>
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Importar
        </Button>
      </Grid>
      <input
        id="upload-xml"
        type="file"
        multiple
        max={1}
        accept="text/xml"
        hidden
        onChange={({ target }) => {
          setFiles((prev) => [...prev, ...Array.from(target?.files)]);
          target.value = '';
        }}
      />
    </Grid>
  );
};

export default ImportaXMLModal;
