import React, { useContext, useState, useEffect } from 'react';
import { Box, Grid, Icon, IconButton, Typography } from '@mui/material';
import { AllInbox, QrCode } from '@mui/icons-material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useForm } from 'react-hook-form';
import { checkInfosProduto } from 'utils/functions';
import { toastWarning } from 'utils/toast';
import { AuthContext } from 'contexts/AuthContext';
import { DropsContext } from 'contexts/DropsContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import Container from 'components/Container';
import VirtualDrop from 'components/VirtualDrop';
import Blank from 'assets/blank.png';
import styles from './styles';
import moment from 'moment';

const Transferência = () => {
  let timer;
  const waitTime = 1000;
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { postLoading, postDocumento } = useContext(DocumentosContext);
  const defaultValues = {
    filial_id: user?.filial_id,
    origem: null,
    destino: null,
    //ITENS
    produto_id: null,
    descricao: '',
    cdbarra: '',
    quantidade: '',
    referencia: '',
    itens: [],
  };
  const [loading, setLoading] = useState(false);
  const [additionals, setAdditionals] = useState(null);
  const [useBarCode, setUserBarCode] = useState(false);
  const { control, handleSubmit, setValue, watch, resetField, reset } = useForm(
    { defaultValues }
  );

  useEffect(() => {
    if (Boolean(watch('produto_id'))) {
      getAdditionals({ submit: Boolean(watch('cdbarra')) });
    } else {
      resetField('cdbarra');
      resetField('quantidade');
      resetField('descricao');
      resetField('referencia');
      setAdditionals(null);
    }
  }, [watch('produto_id')]);

  const getAdditionals = async ({ submit }) => {
    setLoading(true);
    const produto = await checkInfosProduto({
      produto_id: watch('produto_id'),
      filial_id: watch('filial_id'),
      local_id: watch('origem'),
    });
    setAdditionals(produto);
    if (!Boolean(watch('quantidade'))) {
      setValue('quantidade', produto?.volume || 1);
    }
    setValue('descricao', produto?.descricao);
    setValue('referencia', produto?.referencia);
    if (submit) {
      handleSubmit(onAddItem)();
    }
    setLoading(false);
  };

  const onAddItem = (values) => {
    if (!Boolean(values?.produto_id)) {
      return toastWarning('Nenhum produto selecionado');
    }
    if (values?.itens.some((s) => s?.produto_id === values?.produto_id)) {
      return toastWarning('Produto já inserido');
    }
    const itens = [...values?.itens];
    itens?.unshift({
      produto_id: values?.produto_id,
      descricao: values?.descricao,
      quantidade: values?.quantidade,
      referencia: values?.referencia,
    });
    setValue('itens', itens);
    resetField('produto_id');
  };

  const onDeleteItem = (produto) => {
    setValue(
      'itens',
      watch('itens')?.filter((f) => f?.produto_id !== produto?.produto_id)
    );
  };

  const onCellEditCommit = (e) => {
    const editedRows = watch('itens')?.map((item) => {
      if (item?.produto_id === e?.id) {
        return { ...item, [e?.field]: e?.value };
      } else {
        return { ...item };
      }
    });
    setValue('itens', editedRows);
  };

  const onSubmit = (values) => {
    const Itens = values?.itens?.map((item, index) => ({
      sequencia: index + 1,
      ...item,
    }));
    const data = {
      Documento: {
        especie_id: 38,
        natureza_operacao_id: 28,
        users_id: user?.id,
        filial_id: values?.filial_id,
        cadastro_id: values?.filial_id,
        celocal_id: values?.origem,
        celocald_id: values?.destino,
        dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
        conferido: 'SIM',
      },
      Itens,
    };
    if (!Boolean(Itens?.length)) {
      return toastWarning('Digite ao menos um item');
    }
    postDocumento({ data, cb: () => reset() });
  };

  return (
    <Container>
      <Header titulo="Transferência" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card title="Informações Gerais">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Dropdown
                  name="filial_id"
                  control={control}
                  label="Filial"
                  options={drops?.Filial}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Dropdown
                  name="origem"
                  control={control}
                  label="Local de Estoque (Origem)"
                  options={drops?.LocalEstoque?.filter(
                    (f) =>
                      !Boolean(f?.filial_id) ||
                      f?.filial_id === watch('filial_id')
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Dropdown
                  name="destino"
                  control={control}
                  label="Local de Estoque (Destino)"
                  options={drops?.LocalEstoque?.filter(
                    (f) =>
                      !Boolean(f?.filial_id) ||
                      f?.filial_id === watch('filial_id')
                  )}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card title="Itens">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} display="flex">
                <Card style={styles?.semiCard}>
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      {Boolean(useBarCode) ? (
                        <Input
                          name="cdbarra"
                          control={control}
                          label="Código de Barras"
                          onKeyUp={(e) => {
                            clearTimeout(timer);
                            timer = setTimeout(() => {
                              const value = e?.target?.value;
                              if (Boolean(value)) {
                                const produto = drops?.Produto.find(
                                  (f) => f?.cdbarra == e?.target?.value
                                );
                                setValue('produto_id', produto?.value || null);
                              }
                            }, waitTime);
                          }}
                        />
                      ) : (
                        <VirtualDrop
                          name="produto_id"
                          control={control}
                          label="Produto"
                          options={drops?.Produto}
                          refresh="Produto"
                        />
                      )}
                    </Grid>
                    <Grid item xs={2} textAlign="center">
                      <IconButton
                        size="small"
                        onClick={() => setUserBarCode(!useBarCode)}
                      >
                        {Boolean(useBarCode) ? (
                          <AllInbox color="primary" />
                        ) : (
                          <QrCode color="primary" />
                        )}
                      </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                      <InputMask
                        name="quantidade"
                        control={control}
                        label="Quantidade"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} {...styles?.detailsProps}>
                      <Box
                        component="img"
                        sx={styles?.image}
                        src={additionals?.imagem || Blank}
                      />
                    </Grid>
                    {Boolean(loading) ? (
                      <Grid item xs={12} sm={6} {...styles?.detailsProps}>
                        <Loader />
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={6} {...styles?.textDetailsProps}>
                        <Typography variant="h6" color="primary">
                          {additionals?.descricao}
                        </Typography>
                        <Typography variant="body1">
                          Referência: {additionals?.referencia || '-'}
                        </Typography>
                        <Typography variant="body1">
                          Saldo: {additionals?.saldo || '-'}{' '}
                          {additionals?.UnidadeMedida?.descricao || ''}
                        </Typography>
                        <Typography variant="body1">
                          Localização: {additionals?.localizador || '-'}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={12} textAlign="center">
                      <Button
                        variant="contained"
                        color="primary"
                        loading={loading}
                        onClick={handleSubmit(onAddItem)}
                      >
                        ADICIONAR
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <DataGrid
                        rows={watch('itens')}
                        columns={[
                          {
                            field: 'referencia',
                            headerName: 'Referência',
                            flex: 1,
                            sortable: false,
                          },
                          {
                            field: 'descricao',
                            headerName: 'Descrição',
                            flex: 2,
                            sortable: false,
                          },
                          {
                            field: 'quantidade',
                            headerName: 'Quantidade',
                            flex: 1,
                            type: 'number',
                            sortable: false,
                            editable: true,
                          },
                          {
                            field: 'actions',
                            headerName: 'Ações',
                            type: 'actions',
                            flex: 1,
                            getActions: ({ row }) => [
                              <GridActionsCellItem
                                icon={<Icon>delete</Icon>}
                                label="Excluir"
                                onClick={() => onDeleteItem(row)}
                              />,
                            ],
                          },
                        ]}
                        hideFooter
                        autoHeight
                        density="compact"
                        disableSelectionOnClick
                        disableColumnMenu
                        showCellRightBorder
                        showColumnRightBorder
                        getRowId={(row) => row?.produto_id}
                        onCellEditCommit={onCellEditCommit}
                        localeText={{ noRowsLabel: 'Nenhum Registro.' }}
                      />
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Transferência;
