import { createContext, useState } from 'react';
import api from 'services/api';

export const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
  const [dashData, setDashData] = useState(null);
  const [getLoading, setGetLoading] = useState(false);

  const getDashboard = async () => {
    try {
      setGetLoading(true);
      const { data } = await api.get('/Suprimentos/Dashboard');
      setDashData(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <DashboardContext.Provider value={{ dashData, getLoading, getDashboard }}>
      {children}
    </DashboardContext.Provider>
  );
};
