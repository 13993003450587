import React, { forwardRef } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Controller } from 'react-hook-form';

const CustomCheckbox = forwardRef(({ control, name, label, ...props }, ref) => {
  if (control) {
    return (
      <FormControlLabel
        control={
          <Controller
            name={name}
            control={control}
            render={({ field: props }) => (
              <Checkbox
                {...props}
                checked={props?.value}
                onChange={(e) => props?.onChange(e?.target?.checked)}
              />
            )}
          />
        }
        label={label}
      />
    );
  }

  return <Checkbox {...props} />;
});

export default CustomCheckbox;
