import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { dropBoolean } from 'utils/drops';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { useDialog } from 'components/Modals';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import ButtonMenu from 'components/ButtonMenu';
import TableContainer from 'components/TableContainer';
import styles from '../styles';
import moment from 'moment';

const Processo = ({
  drops,
  onSubmit,
  onDelete,
  loading,
  disableActions,
  onSubmitProcesso,
  documentoItem = {},
}) => {
  const { openDialog } = useDialog();
  const rotina = 'DocumentoItemProcesso';

  const onConfirm = (values) => {
    const data = {
      Itens: [
        {
          id: documentoItem?.id,
          documento_id: documentoItem?.documento_id,
          Processos: [values],
        },
      ],
    };

    onSubmit(data);
  };

  const Modal = ({ item }) => {
    const defaultValues = {
      id: item?.id,
      documento_item_id: item?.documento_item_id,
      operacao: item?.operacao || '',
      descricao: item?.descricao || '',
      apontamento: item?.apontamento || null,
      ccusto_id: item?.ccusto_id || null,
      tpreparacao: item?.tpreparacao || '',
      texecucao: item?.texecucao || '',
      analisa_id: item?.analisa_id || null,
      aprova_id: item?.aprova_id || null,
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(DocumentosContext);

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="operacao" control={control} label="Operação" />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Input name="descricao" control={control} label="Descrição" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="apontamento"
            control={control}
            label="Apontamento"
            options={dropBoolean}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Dropdown
            name="ccusto_id"
            control={control}
            label="Centro de Custo"
            options={drops?.Ccusto}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            name="tpreparacao"
            control={control}
            label="Tempo de Preparação"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            name="texecucao"
            control={control}
            label="Tempo de Execução"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="analisa_id"
            control={control}
            label="Responsável pela Análise"
            options={drops?.Users}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="aprova_id"
            control={control}
            label="Responsável pela Aprovação"
            options={drops?.Users}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onConfirm)}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const options = [
    {
      name: 'Adicionar Processo',
      icon: 'add',
      action: () => openDialog(<Modal />, 'Adicionar Processo'),
    },
    {
      name: 'Recarregar Processo',
      icon: 'refresh',
      action: () => onSubmitProcesso({ id: documentoItem?.id }),
    },
  ];

  return (
    <Card title="Processos" style={styles?.card}>
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <ButtonMenu
              buttonProps={{ variant: 'outlined', loading }}
              options={options}
            />
          </Grid>
        )}
        {!!documentoItem?.DocumentoItemProcessos?.length && (
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Operação</TableCell>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Centro de Custo</TableCell>
                    <TableCell>Tempo de Preparo</TableCell>
                    <TableCell>Tempo de Execução</TableCell>
                    <TableCell>Apontamento</TableCell>
                    <TableCell>Responsável pela Análise</TableCell>
                    <TableCell>Responsável pela Aprovação</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documentoItem?.DocumentoItemProcessos?.map((item) => (
                    <TableRow key={item?.id?.toString()}>
                      <TableCell>{item?.operacao}</TableCell>
                      <TableCell>{item?.descricao}</TableCell>
                      <TableCell>{item?.Ccusto?.descricao}</TableCell>
                      <TableCell>{item?.tpreparacao}</TableCell>
                      <TableCell>{item?.texecucao}</TableCell>
                      <TableCell>{item?.apontamento}</TableCell>
                      <TableCell>{item?.analisa}</TableCell>
                      <TableCell>{item?.aprova}</TableCell>
                      <TableCell align="center">
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() =>
                              openDialog(
                                <Modal item={item} />,
                                'Editar Processo'
                              )
                            }
                          >
                            <Edit />
                          </IconButton>
                        )}
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() => onDelete({ rotina, id: item?.id })}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Processo;
