import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { summarizer } from 'utils/functions';
import { toastWarning } from 'utils/toast';
import { DropsContext } from 'contexts/DropsContext';
import { AuthContext } from 'contexts/AuthContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Header from 'components/Header';
import Button from 'components/Button';
import Input from 'components/Input';
import Card from 'components/Card';
import Loader from 'components/Loader';

const Gerar = () => {
  let timer;
  const waitTime = 1000;
  const defaultValues = {
    celocal_id: null,
    cdbarra: '',
    Itens: [],
  };
  const { documento_id } = useParams();
  const { drops } = useContext(DropsContext);
  const { control, handleSubmit, watch, setValue } = useForm({ defaultValues });
  const { getDocumento, getLoading, documento, postDocumento, postLoading } =
    useContext(DocumentosContext);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (Boolean(documento_id)) {
      getDocumento(documento_id);
    }
  }, []);

  useEffect(() => {
    if (Boolean(documento_id) && Boolean(documento)) {
      const itens = documento?.DocumentoItems?.map((di) => ({
        id: di?.id,
        documento_id: di?.documento_id,
        produto_id: di?.produto_id,
        sequencia: di?.sequencia,
        referencia: di?.referencia,
        descricao: di?.descricao,
        cdbarra: di?.cdbarra,
        saldo: di?.saldo,
        quantidade: di?.quantidade,
        expedido: Math.abs(summarizer(di?.Ceexpedicaos, 'quantidade') || 0),
        qtmovimento: 0,
        cdbarra: di?.Produto?.cdbarra,
      }));
      setValue('Itens', itens);
    }
  }, [documento]);

  const onScan = (values) => {
    const find = values?.Itens?.find(
      (f) =>
        f?.cdbarra == values?.cdbarra &&
        f?.expedido + f?.qtmovimento < f?.quantidade
    );
    if (Boolean(find)) {
      const itens = values?.Itens?.map((p) =>
        p?.id === find?.id ? { ...p, qtmovimento: p?.qtmovimento + 1 } : p
      );
      setValue('Itens', itens);
    } else {
      toastWarning('Produto não encontrado no pedido');
    }
    setValue('cdbarra', '');
  };

  const onCellEditCommit = (e) => {
    const itens = watch('Itens')?.map((c) => {
      if (c?.id === e?.id) {
        if (c?.expedido + e?.value <= c?.quantidade) {
          return { ...c, [e?.field]: e?.value };
        } else {
          return { ...c, [e?.field]: c?.quantidade - c?.expedido };
        }
      } else {
        return { ...c };
      }
    });
    setValue('Itens', itens);
  };

  const onSubmit = (values) => {
    const Itens = values?.Itens?.filter((f) => f?.qtmovimento !== 0)?.map(
      (item) => ({
        id: item?.id,
        documento_id: item?.documento_id,
        Expedicoes: [
          {
            documento_item_id: item?.id,
            produto_id: item?.produto_id,
            users_id: user?.id,
            celocal_id: values?.celocal_id,
            quantidade: Math.abs(item?.qtmovimento) * -1,
          },
        ],
      })
    );
    postDocumento({ data: { Itens }, cb: () => getDocumento(documento_id) });
  };

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo="Expedição" />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="celocal_id"
              control={control}
              label="Local de Estoque"
              options={drops?.LocalEstoque?.filter(
                (f) =>
                  !Boolean(f?.filial_id) ||
                  f?.filial_id === documento?.filial_id
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="cdbarra"
              control={control}
              label="Código de Barras"
              onKeyUp={(e) => {
                clearTimeout(timer);
                timer = setTimeout(() => {
                  const value = e?.target?.value;
                  if (Boolean(value)) handleSubmit(onScan)();
                }, waitTime);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              rows={watch('Itens')}
              columns={[
                {
                  field: 'sequencia',
                  headerName: 'Sequencia',
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'referencia',
                  headerName: 'Referência',
                  flex: 2,
                  sortable: false,
                },
                {
                  field: 'descricao',
                  headerName: 'Descrição',
                  flex: 4,
                  sortable: false,
                },
                {
                  field: 'cdbarra',
                  headerName: 'Código de Barras',
                  flex: 3,
                  sortable: false,
                },
                {
                  field: 'saldo',
                  headerName: 'Saldo',
                  type: 'number',
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'quantidade',
                  headerName: 'Quantidade',
                  type: 'number',
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'expedido',
                  headerName: 'Expedido',
                  type: 'number',
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'expedir',
                  headerName: 'À Expedir',
                  type: 'number',
                  flex: 1,
                  sortable: false,
                  valueGetter: ({ row }) =>
                    row?.quantidade - row?.expedido - row?.qtmovimento,
                },
                {
                  field: 'qtmovimento',
                  headerName: 'Expedindo',
                  type: 'number',
                  flex: 1,
                  sortable: false,
                  editable: true,
                },
              ]}
              hideFooter
              autoHeight
              density="compact"
              disableSelectionOnClick
              disableColumnMenu
              showCellRightBorder
              showColumnRightBorder
              localeText={{ noRowsLabel: 'Nenhum Registro.' }}
              onCellEditCommit={onCellEditCommit}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              color="primary"
              variant="contained"
              loading={postLoading}
              onClick={handleSubmit(onSubmit)}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Gerar;
