import React, { useContext, useState, useEffect } from 'react';
import {
  Box,
  Grid,
  IconButton,
  InputLabel,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { AllInbox, Delete, QrCode } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { checkInfosProduto } from 'utils/functions';
import { toastWarning } from 'utils/toast';
import { AuthContext } from 'contexts/AuthContext';
import { DropsContext } from 'contexts/DropsContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import Container from 'components/Container';
import VirtualDrop from 'components/VirtualDrop';
import TableContainer from 'components/TableContainer';
import Blank from 'assets/blank.png';
import styles from './styles';
import moment from 'moment';

const Movimentação = () => {
  let timer;
  const waitTime = 1000;
  const { user } = useContext(AuthContext);
  const defaultValues = {
    filial_id: user?.filial_id,
    celocal_id: null,
    documento: '',
    dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
    observacao: '',
    operacao: 'ENTRADA',
    //ITENS
    produto_id: null,
    cdbarra: '',
    quantidade: '',
    descricao: null,
    referencia: '',
    saldo: null,
    itens: [],
  };

  const [loading, setLoading] = useState(false);
  const [additionals, setAdditionals] = useState(null);
  const [useBarCode, setUserBarCode] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    resetField,
    getValues,
    reset,
  } = useForm({
    defaultValues,
  });
  const { drops } = useContext(DropsContext);
  const { postLoading, postDocumento } = useContext(DocumentosContext);

  useEffect(() => {
    const { produto_id, cdbarra } = getValues();
    if (Boolean(produto_id)) {
      getAdditionals({ submit: Boolean(cdbarra) && Boolean(produto_id) });
    } else {
      resetField('cdbarra');
      resetField('quantidade');
      resetField('descricao');
      resetField('referencia');
      resetField('saldo');
      setAdditionals(null);
    }
  }, [watch('produto_id')]);

  const getAdditionals = async ({ submit }) => {
    const {
      filial_id,
      dtemissao,
      produto_id,
      quantidade,
      celocal_id,
      ...rest
    } = getValues();
    setLoading(true);
    const produto = await checkInfosProduto({
      filial_id,
      produto_id,
      tipo_saldo: 'FISICO',
      local_id: celocal_id,
      tempo_saldo: dtemissao && moment(dtemissao).format('YYYY-MM-DD HH:mm:ss'),
    });
    setAdditionals(produto);
    setValue('saldo', produto?.saldo || null);
    if (!Boolean(quantidade)) setValue('quantidade', produto?.volume || 1);
    setValue('descricao', produto?.descricao || null);
    setValue('referencia', produto?.referencia || null);
    if (submit) {
      handleSubmit(onAddItem)();
    }
    setLoading(false);
  };

  const onAddItem = (values) => {
    if (!Boolean(values?.produto_id)) {
      toastWarning('Nenhum produto selecionado');
    } else {
      if (values?.itens.some((s) => s?.produto_id === values?.produto_id)) {
        toastWarning('Produto já inserido');
      } else {
        setValue('itens', [
          {
            produto_id: values?.produto_id,
            descricao: values?.descricao,
            referencia: values?.referencia,
            quantidade: values?.quantidade,
            referencia: values?.referencia,
            saldo: values?.saldo,
          },
          ...values?.itens,
        ]);
        resetField('produto_id');
      }
    }
  };

  const onDeleteItem = (index) => {
    setValue(
      'itens',
      watch('itens')?.filter((_, i) => i !== index)
    );
  };

  const onSubmit = (values) => {
    const especie_id =
      values?.operacao === 'ENTRADA'
        ? 14
        : values?.operacao === 'SAIDA'
        ? 38
        : null;
    const natureza_operacao_id =
      values?.operacao === 'ENTRADA'
        ? 17
        : values?.operacao === 'SAIDA'
        ? 28
        : null;
    const data = {
      Documento: {
        especie_id,
        natureza_operacao_id,
        users_id: user?.id,
        filial_id: values?.filial_id,
        cadastro_id: values?.filial_id,
        celocal_id: values?.celocal_id,
        documento: values?.documento,
        dtemissao: values?.dtemissao,
        observacao: values?.observacao,
        conferido: 'SIM',
      },
      Itens: values?.itens?.map((item, index) => ({
        sequencia: index + 1,
        produto_id: item?.produto_id,
        descricao: item?.descricao,
        referencia: item?.referencia,
        quantidade: item?.quantidade,
        dhsaldo: values?.dtemissao,
      })),
    };

    postDocumento({
      data,
      cb: () => {
        reset();
        setValue('dtemissao', moment().format('YYYY-MM-DD HH:mm:ss'));
      },
    });
  };

  return (
    <Container>
      <Header titulo="Movimentação de Estoque" />
      <Card title="Informações Gerais">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Dropdown
              name="filial_id"
              control={control}
              label="Filial"
              options={drops?.Filial}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="celocal_id"
              control={control}
              label="Local de Estoque"
              options={drops?.LocalEstoque?.filter(
                (f) =>
                  !Boolean(f?.filial_id) || f?.filial_id === watch('filial_id')
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              name="documento"
              control={control}
              label="Nº do Documento"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask
              name="dtemissao"
              control={control}
              label="Data de Movimentação"
              type="datetime"
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name="observacao"
              control={control}
              label="Observação"
              multiline
              rows={5}
            />
          </Grid>
          <Grid item xs={6} display="flex" alignItems="center">
            <Radio
              color="primary"
              size="small"
              checked={watch('operacao') === 'ENTRADA'}
              onChange={() => setValue('operacao', 'ENTRADA')}
            />
            <InputLabel>Movimentação de Entrada</InputLabel>
          </Grid>
          <Grid item xs={6} display="flex" alignItems="center">
            <Radio
              color="primary"
              size="small"
              checked={watch('operacao') === 'SAIDA'}
              onChange={() => setValue('operacao', 'SAIDA')}
            />
            <InputLabel>Movimentação de Saída</InputLabel>
          </Grid>
        </Grid>
      </Card>
      <Card title="Itens" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} display="flex">
            <Card style={styles?.semiCard}>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  {Boolean(useBarCode) ? (
                    <Input
                      name="cdbarra"
                      control={control}
                      label="Código de Barras"
                      onKeyUp={(e) => {
                        clearTimeout(timer);
                        timer = setTimeout(() => {
                          const value = e?.target?.value;
                          if (Boolean(value)) {
                            const produto = drops?.Produto.find(
                              (f) => f?.cdbarra == e?.target?.value
                            );
                            setValue('produto_id', produto?.value || null);
                          }
                        }, waitTime);
                      }}
                    />
                  ) : (
                    <VirtualDrop
                      name="produto_id"
                      control={control}
                      label="Produto"
                      options={drops?.Produto}
                      refresh="Produto"
                    />
                  )}
                </Grid>
                <Grid item xs={2} textAlign="center">
                  <IconButton
                    size="small"
                    onClick={() => setUserBarCode(!useBarCode)}
                  >
                    {Boolean(useBarCode) ? (
                      <AllInbox color="primary" />
                    ) : (
                      <QrCode color="primary" />
                    )}
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <InputMask
                    name="quantidade"
                    control={control}
                    label="Quantidade"
                  />
                </Grid>
                <Grid item xs={12} sm={6} {...styles?.detailsProps}>
                  <Box
                    component="img"
                    sx={styles?.image}
                    src={additionals?.imagem || Blank}
                  />
                </Grid>
                {Boolean(loading) ? (
                  <Grid item xs={12} sm={6} {...styles?.detailsProps}>
                    <Loader />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={6} {...styles?.textDetailsProps}>
                    <Typography variant="h6" color="primary">
                      {additionals?.descricao}
                    </Typography>
                    <Typography variant="body1">
                      Referência: {additionals?.referencia || '-'}
                    </Typography>
                    <Typography variant="body1">
                      Saldo: {additionals?.saldo || '-'}{' '}
                      {additionals?.UnidadeMedida?.descricao || ''}
                    </Typography>
                    <Typography variant="body1">
                      Localização: {additionals?.localizador || '-'}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} textAlign="center">
                  <Button
                    variant="contained"
                    color="primary"
                    loading={loading}
                    onClick={handleSubmit(onAddItem)}
                  >
                    ADICIONAR
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} display="flex">
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Referência</TableCell>
                          <TableCell>Descrição</TableCell>
                          <TableCell>Quantidade</TableCell>
                          <TableCell>Saldo Atual</TableCell>
                          <TableCell align="center">Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {watch('itens')?.map((item, index) => (
                          <TableRow key={index?.toString()}>
                            <TableCell>{item?.referencia}</TableCell>
                            <TableCell>{item?.descricao}</TableCell>
                            <TableCell>{item?.quantidade}</TableCell>
                            <TableCell>{item?.saldo}</TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={() => onDeleteItem(index)}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Card>
      <Grid item xs={12} textAlign="center" sx={styles?.button}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
      </Grid>
    </Container>
  );
};

export default Movimentação;
