import { useContext, useState } from 'react';
import { Box, Grid, Icon, IconButton, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { AuthContext } from 'contexts/AuthContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { RequisicoesContext } from 'contexts/RequisicoesContext';
import Button from 'components/Button';
import moment from 'moment';

const CarrinhoModal = ({ callback }) => {
  const { postDocumentos, postLoading } = useContext(DocumentosContext);
  const { pedidos, setPedidos } = useContext(RequisicoesContext);
  const { user } = useContext(AuthContext);
  const [peds, setPeds] = useState(pedidos);

  const onDelete = (pd) => {
    let newPeds = [...peds];
    newPeds = newPeds?.filter((f) => f?.cadastro_id !== pd?.cadastro_id);

    setPeds(newPeds);
    setPedidos(newPeds);
  };

  const onDeleteItem = (row) => {
    let newPeds = [...peds];
    newPeds = newPeds?.map((n) => {
      if (row?.cadastro_id === n?.cadastro_id) {
        return {
          ...n,
          itens: (n?.itens || [])?.filter((f) => f?.id !== row?.id),
        };
      }
      return n;
    });
    newPeds = newPeds?.filter((f) => Boolean(f?.itens?.length));

    setPeds(newPeds);
    setPedidos(newPeds);
  };

  const onCellEditCommit = ({ id, field, value }) => {
    let newPeds = [...peds];
    newPeds = newPeds?.map((n) => {
      return {
        ...n,
        itens: (n?.itens || [])?.map((i) => {
          if (i?.id === id) {
            return { ...i, [field]: value };
          }
          return i;
        }),
      };
    });

    newPeds = newPeds
      ?.map((n) => ({
        ...n,
        itens: (n?.itens || [])?.filter((f) => Number(f?.quantidade) > 0),
      }))
      ?.filter((f) => Boolean(f?.itens?.length));

    setPeds(newPeds);
    setPedidos(newPeds);
  };

  const onSubmit = () => {
    const dtemissao = moment().format('YYYY-MM-DD HH:mm:ss');
    const data = peds?.map((p) => ({
      Documento: {
        especie_id: 4,
        natureza_operacao_id: 1,
        users_id: user?.id,
        filial_id: p?.filial_id,
        cadastro_id: p?.cadastro_id,
        dtemissao,
      },
      Itens: (p?.itens || [])?.map((r) => ({
        Origens: [{ documento_item_org_id: r?.id }],
        produto_id: r?.produto_id,
        descricao: r?.descricao,
        quantidade: r?.quantidade,
        unidade_medida_id: r?.unidade_medida_id,
        referencia: r?.referencia,
        desconto1_pc: r?.desconto1_pc,
        desconto_vlr: r?.desconto_vlr || 0,
        preco: r?.preco,
        przent: r?.przent,
        planoconta_id: r?.planoconta_id,
        ccusto_id: r?.ccusto_id,
        aplicacao: r?.aplicacao,
        frota: r?.frota,
        infadc: r?.infadc,
      })),
      Entrada: {
        dtentrada: dtemissao,
      },
      PedidoCompra: {},
    }));

    postDocumentos({ data, cb: callback });
  };

  return (
    <Grid container spacing={2}>
      {Boolean(peds?.length) ? (
        peds?.map((p, i) => (
          <Grid item xs={12} key={`${i}`}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6" mr={2}>
                • {p?.cadastro}
              </Typography>
              <IconButton size="small" onClick={() => onDelete(p)}>
                <Icon>delete</Icon>
              </IconButton>
            </Box>
            <DataGrid
              rows={p?.itens || []}
              sx={{ mt: 2, mb: 6 }}
              columns={[
                {
                  field: 'descricao',
                  headerName: 'Produto',
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'referencia',
                  headerName: 'Referência',
                  width: 150,
                  sortable: false,
                },
                {
                  field: 'quantidade',
                  headerName: 'Quantidade',
                  type: 'number',
                  width: 150,
                  editable: true,
                  sortable: false,
                  renderCell: ({ row }) => (
                    <Typography variant="body2">
                      {row.quantidade || 0} {row?.unidade_medida}
                    </Typography>
                  ),
                },
                {
                  field: 'preco',
                  headerName: 'Preço',
                  type: 'number',
                  width: 150,
                  editable: true,
                  sortable: false,
                  renderCell: ({ row }) => (
                    <Typography variant="body2">
                      {(row?.preco || 0)?.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </Typography>
                  ),
                },
                {
                  field: 'subtotal',
                  headerName: 'Subtotal',
                  type: 'number',
                  width: 150,
                  valueGetter: ({ row }) =>
                    (
                      (row?.preco || 0) * (row?.quantidade || 0)
                    )?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }),
                  sortable: false,
                },
                {
                  field: 'actions',
                  headerName: 'Ações',
                  type: 'actions',
                  width: 100,
                  getActions: ({ row }) => [
                    <GridActionsCellItem
                      icon={<Icon>delete</Icon>}
                      label="Excluir"
                      onClick={() => onDeleteItem(row)}
                    />,
                  ],
                },
                ,
              ]}
              hideFooter
              autoHeight
              density="compact"
              disableSelectionOnClick
              disableColumnMenu
              showCellRightBorder
              showColumnRightBorder
              localeText={{ noRowsLabel: 'Nenhum Registro.' }}
              onCellEditCommit={onCellEditCommit}
            />
          </Grid>
        ))
      ) : (
        <Grid item xs={12} textAlign="center">
          <Typography variant="body2">Nenhum pedido para ser gerado</Typography>
        </Grid>
      )}
      {Boolean(peds?.length) && (
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            loading={postLoading}
          >
            Gerar Pedidos
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default CarrinhoModal;
