import React, { useContext, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { DropsContext } from 'contexts/DropsContext';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Input from 'components/Input';

const ItemModal = ({ item, onSubmit }) => {
  const { drops } = useContext(DropsContext);

  const defaultValues = {
    descricao: item?.descricao || '',
    quantidade: item?.quantidade || '',
    unidade_medida_id: item?.unidade_medida_id || null,
    referencia: item?.referencia || '',
    desconto1_pc: item?.desconto1_pc || '',
    desconto_vlr: item?.desconto_vlr || '',
    przent: item?.przent || null,
    preco: item?.preco || '',
    infadc: item?.infadc || '',
  };
  const { control, watch, setValue, handleSubmit } = useForm({
    defaultValues,
  });

  useEffect(() => {
    const desconto_vlr =
      watch('desconto1_pc') * watch('preco') * watch('quantidade');
    setValue(
      'desconto_vlr',
      desconto_vlr !== 0 ? desconto_vlr * -0.01 : desconto_vlr
    );
  }, [watch('desconto1_pc'), watch('preco'), watch('quantidade')]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" component="div" align="center">
          {item?.descricao}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Input name="descricao" control={control} label="Descrição" />
      </Grid>
      <Grid item xs={12} sm={4}>
        <InputMask name="quantidade" control={control} label="Quantidade" />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Dropdown
          name="unidade_medida_id"
          control={control}
          label="Unidade de Medida"
          options={drops?.UnidadeMedida}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Input name="referencia" control={control} label="Referência" />
      </Grid>
      <Grid item xs={12} sm={4}>
        <InputMask name="desconto1_pc" control={control} label="% Desconto" />
      </Grid>
      <Grid item xs={12} sm={4}>
        <InputMask name="preco" control={control} label="Preço" />
      </Grid>
      <Grid item xs={12} sm={4}>
        <InputMask
          name="przent"
          control={control}
          label="Prazo de Entrega"
          type="date"
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          name="infadc"
          control={control}
          label="Informações Adicionais"
          multiline
          rows={5}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};
export default ItemModal;
