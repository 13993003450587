import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toastWarning } from 'utils/toast';
import { AuthContext } from 'contexts/AuthContext';
import { DropsContext } from 'contexts/DropsContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { EstoqueContext } from 'contexts/EstoqueContext';
import { GridContext } from 'contexts/GridContext';
import { useModal } from 'components/Modals';
import ResumoModal from 'components/Modals/ResumoModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import Filter from './filter';
import mock from './mock';

const Estoque = () => {
  const rotina = 'Estoque';
  const titulo = 'Movimentações de Estoque';
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { postDocumentos } = useContext(DocumentosContext);
  const { exportPdf, exportXls } = useContext(GridContext);
  const { estoque, getEstoque, getLoading } = useContext(EstoqueContext);
  const { control, getValues, reset } = useForm({
    defaultValues: estoque?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getEstoque({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({ rotina, filter: getValues(), order: estoque?.order }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({ rotina, filter: getValues(), order: estoque?.order }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={estoque?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
    {
      name: 'Gerar Inventário',
      icon: 'inventory_2',
      action: () => navigate('/app/Estoque/Inventario'),
      show: !user?.permissoes?.block_estoque_inventario,
    },
    {
      name: 'Gerar Transferência',
      icon: 'sync',
      action: () => navigate('/app/Estoque/Transferencia'),
      show: !user?.permissoes?.block_estoque_transferencia,
    },
    {
      name: 'Gerar Movimentação',
      icon: 'subdirectory_arrow_right',
      action: () => navigate('/app/Estoque/Movimentacao'),
      show: !user?.permissoes?.block_estoque_movimentacao,
    },
    {
      name: 'Registrar Documentos',
      icon: 'send',
      action: (selected) => {
        const documentos = selected?.filter((f) =>
          Boolean(f?.status !== 'CONCLUIDO')
        );
        if (!Boolean(documentos?.length)) {
          return toastWarning('Selecione apenas documentos não concluídos');
        } else {
          postDocumentos({
            data: selected?.map((s) => ({
              Documento: { id: s?.id, conferido: 'SIM' },
            })),
            cb: loadGrid,
          });
        }
      },
    },
  ];
  const rowOptions = [
    {
      name: 'Aprovação necessária',
      icon: 'priority_high',
      color: 'secondary.main',
      show: ({ row }) => Boolean(row?.aprovacao),
    },
    {
      name: 'Detalhes',
      icon: 'visibility',
      action: ({ row }) => navigate(`/app/Documento/${row?.id}`),
    },
  ];

  return (
    <Container>
      <Header rotina={rotina} titulo={titulo} colunas={estoque?.colunas} />
      <Card>
        <Grid
          grid={estoque}
          mock={mock}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={estoque} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default Estoque;
